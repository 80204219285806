import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color}
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineCat: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.625 5.25H16.5V3.5625C16.5 3.28125 16.2188 3 15.9375 3C15.6211 3 15.375 3.28125 15.375 3.5625V5.25H8.625V3.5625C8.625 3.28125 8.34375 3 8.0625 3C7.74609 3 7.5 3.28125 7.5 3.5625V5.25H6.375C5.10938 5.25 4.125 6.26953 4.125 7.5V18.75C4.125 20.0156 5.10938 21 6.375 21H17.625C18.8555 21 19.875 20.0156 19.875 18.75V7.5C19.875 6.26953 18.8555 5.25 17.625 5.25ZM6.375 6.375H17.625C18.2227 6.375 18.75 6.90234 18.75 7.5V8.625H5.25V7.5C5.25 6.90234 5.74219 6.375 6.375 6.375ZM17.625 19.875H6.375C5.74219 19.875 5.25 19.3828 5.25 18.75V9.75H18.75V18.75C18.75 19.3828 18.2227 19.875 17.625 19.875Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineCat;
