import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineCrown: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-crown"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 7C19.9375 7 19.5 7.46875 19.5 8C19.5 8.25 19.5938 8.46875 19.7188 8.625L16.4375 11.25C16.25 11.4063 16.0313 11.4688 15.8125 11.4688C15.4688 11.4688 15.0938 11.25 14.9375 10.9063L12.9375 6.90625C13.25 6.75 13.5 6.40625 13.5 6C13.5 5.46875 13.0312 5 12.5 5C11.9375 5 11.5 5.46875 11.5 6C11.5 6.40625 11.7188 6.75 12.0312 6.90625L10.0313 10.9063C9.875 11.25 9.5 11.4688 9.15625 11.4688C8.9375 11.4688 8.71875 11.4063 8.53125 11.25L5.25 8.625C5.375 8.46875 5.5 8.25 5.5 8C5.5 7.46875 5.03125 7 4.5 7C3.9375 7 3.5 7.46875 3.5 8C3.5 8.5625 3.9375 9 4.5 9C4.53125 9 4.59375 9 4.65625 8.96875L6.1875 17.375C6.34375 18.3125 7.1875 19 8.15625 19H16.8125C17.7813 19 18.625 18.3125 18.7813 17.375L20.3125 8.96875C20.375 9 20.4375 9 20.5 9C21.0312 9 21.5 8.5625 21.5 8C21.5 7.46875 21.0312 7 20.5 7ZM17.8125 17.1875C17.7188 17.6563 17.3125 18 16.8125 18H8.15625C7.65625 18 7.25 17.6563 7.15625 17.1875L5.9375 10.4688L7.90625 12.0313C8.25 12.3125 8.6875 12.4688 9.15625 12.4688C9.90625 12.4688 10.5938 12.0313 10.9375 11.3438L12.5 8.25L14.0312 11.3438C14.375 12.0313 15.0625 12.4688 15.8125 12.4688C16.2813 12.4688 16.7188 12.3125 17.0625 12.0313L19.0313 10.4688L17.8125 17.1875Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineCrown;
