import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineChevronRight: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-outline-chevron-right',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-chevron-right"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M9.16036 16.9021C9.05345 16.8351 9 16.7458 9 16.6565C9 16.5671 9.05345 16.4778 9.13363 16.4108L13.971 12.0112L9.13363 7.5892C8.97327 7.4552 8.97327 7.23187 9.16036 7.09787C9.32071 6.96387 9.58797 6.96387 9.74833 7.1202L14.8797 11.7655C15.0401 11.8995 15.0401 12.1005 14.8797 12.2345L9.74833 16.8798C9.58797 17.0361 9.32071 17.0361 9.16036 16.9021Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconOutlineChevronRight;
