import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationPadlock: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-padlock',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 43 60"
      fill="none"
      id={id}
      data-testid="icon-illustration-padlock"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5589 59.1556H7.44157C3.62646 59.1556 0.522461 56.0516 0.522461 52.2365V28.7413C0.522461 24.9262 3.62646 21.8222 7.44157 21.8222H35.5589C39.374 21.8222 42.478 24.9262 42.478 28.7413V52.2365C42.478 56.0516 39.374 59.1556 35.5589 59.1556ZM7.44157 23.9556C4.80335 23.9556 2.65579 26.1031 2.65579 28.7413V52.2365C2.65579 54.8747 4.80335 57.0222 7.44157 57.0222H35.5589C38.1971 57.0222 40.3447 54.8747 40.3447 52.2365V28.7413C40.3447 26.1031 38.1971 23.9556 35.5589 23.9556H7.44157Z"
        fill={color}
      />
      <path
        d="M15.527 22.8889H13.3937V13.3956C13.3937 9.02578 16.9493 5.46667 21.3226 5.46667C25.6959 5.46667 29.1946 8.96534 29.2479 13.2889H32.487C32.0319 7.5289 27.1999 2.97779 21.3226 2.97779C15.1466 2.97779 10.1226 8.00178 10.1226 14.1778V22.8889H7.98926V14.1778C7.98926 6.8249 13.9697 0.844452 21.3226 0.844452C28.6755 0.844452 34.6559 6.8249 34.6559 14.1778V14.2489C34.6559 14.896 34.1297 15.4222 33.4826 15.4222H27.1324L27.1181 13.3956C27.1181 10.1991 24.519 7.60001 21.3226 7.60001C18.1261 7.60001 15.527 10.1991 15.527 13.3956V22.8889Z"
        fill={color}
      />
      <path
        d="M41.4116 20.7556H34.656C34.0658 20.7556 33.5894 20.2791 33.5894 19.6889C33.5894 19.0987 34.0658 18.6222 34.656 18.6222H41.4116C42.0018 18.6222 42.4782 19.0987 42.4782 19.6889C42.4782 20.2791 42.0018 20.7556 41.4116 20.7556Z"
        fill={color}
      />
      <path
        d="M34.7765 17.744C34.3072 17.744 33.8805 17.4347 33.749 16.9618C33.5925 16.3929 33.9268 15.8062 34.4921 15.6498L41.0023 13.8507C41.5712 13.6942 42.1579 14.0284 42.3143 14.5938C42.4708 15.1627 42.1365 15.7493 41.5712 15.9058L35.061 17.7049C34.965 17.7298 34.869 17.744 34.7765 17.744Z"
        fill={color}
      />
      <path
        d="M21.5178 50.7787C20.1703 50.7787 16.7818 48.528 14.9543 46.9707C13.1551 45.4382 9.80937 42.5867 9.80226 37.7511C9.79515 33.0436 13.148 30.224 16.3978 30.2169H16.4085C18.8049 30.2169 20.5329 31.6569 21.5071 32.7591C22.4956 31.6534 24.2378 30.2027 26.5809 30.1991H26.5916C28.2378 30.1991 29.8876 30.9351 31.1178 32.2187C32.4583 33.616 33.1978 35.568 33.2014 37.7085C33.2085 42.544 29.8734 45.4062 28.0778 46.9458C26.2787 48.4889 22.9507 50.7716 21.5249 50.7751L21.5178 50.7787ZM16.4049 32.3502H16.3978C14.2005 32.3538 11.9285 34.3734 11.932 37.7476C11.9391 41.3031 14.2076 43.536 16.3338 45.3458C18.4103 47.1165 20.9063 48.5173 21.5285 48.6418C22.0725 48.5458 24.4903 47.2053 26.6805 45.328C28.8031 43.5076 31.0645 41.2711 31.0574 37.7156C31.0574 35.6747 30.2431 34.4054 29.5676 33.6978C28.7356 32.8302 27.6476 32.336 26.5809 32.336H26.5738C24.092 32.3396 22.4174 35.0525 22.3996 35.0809C22.204 35.4045 21.8449 35.5929 21.4716 35.5965C21.0947 35.5929 20.7463 35.3902 20.5578 35.0596C20.5436 35.0347 18.9543 32.3502 16.3943 32.3502H16.4049Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationPadlock;
