import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineQuestionCircle: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-question-circle"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 4C8.0625 4 4.5 7.59375 4.5 12C4.5 16.4375 8.0625 20 12.5 20C16.9063 20 20.5 16.4375 20.5 12C20.5 7.59375 16.9063 4 12.5 4ZM12.5 19C8.625 19 5.5 15.875 5.5 12C5.5 8.15625 8.625 5 12.5 5C16.3438 5 19.5 8.15625 19.5 12C19.5 15.875 16.3438 19 12.5 19ZM12 14.75C11.5625 14.75 11.25 15.0938 11.25 15.5C11.25 15.9375 11.5625 16.25 12 16.25C12.4063 16.25 12.75 15.9375 12.75 15.5C12.75 15.0938 12.4063 14.75 12 14.75ZM13.4063 8H11.5625C10.4063 8 9.5 8.9375 9.5 10.0938V10.375C9.5 10.6563 9.71875 10.875 10 10.875C10.25 10.875 10.5 10.6563 10.5 10.375V10.0938C10.5 9.5 10.9688 9 11.5625 9H13.4063C14 9 14.5 9.5 14.5 10.0938C14.5 10.4688 14.2813 10.8438 13.9375 11.0313L12.0625 11.9688C11.7188 12.1563 11.5 12.5313 11.5 12.9375V13.5C11.5 13.7813 11.7188 14 12 14C12.25 14 12.5 13.7813 12.5 13.5V12.9375C12.5 12.9063 12.5 12.875 12.5313 12.8438L14.4063 11.9063C15.0625 11.5313 15.5 10.8438 15.5 10.0938C15.5 8.9375 14.5625 8 13.4063 8Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineQuestionCircle;
