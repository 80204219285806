import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationPaw: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-paw',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-paw"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.4222 75.7334C50.5778 75.7334 49.7778 75.6445 48.9333 75.5112C44.6222 74.6668 41.5111 71.7779 39.9556 70.0001C38.4 71.7779 35.2889 74.6668 30.9778 75.5112C27.2889 76.2223 23.5111 75.3334 19.8222 72.8001C17.1556 70.9779 15.5111 68.5334 14.9778 65.5112C13.7333 58.8445 18 51.7334 19.9111 48.9779C25.5556 40.8001 35.3333 33.0668 40 33.0668C45.0222 33.0668 54.0444 40.2223 60.0889 48.9779C62 51.7334 66.2667 58.8445 65.0222 65.5112C64.4444 68.5334 62.8 70.9779 60.1778 72.8001C57.2444 74.7557 54.3111 75.7334 51.4222 75.7334ZM40 66.489C40.4444 66.489 40.8444 66.7112 41.1111 67.0668C41.1556 67.1112 44.3556 71.8668 49.5111 72.889C52.4889 73.4668 55.5556 72.7112 58.6667 70.5779C60.7111 69.2001 61.9556 67.289 62.4 65.0223C63.1555 61.0668 61.4667 55.6445 57.9111 50.489C52.0889 42.0445 43.6889 35.7334 40.0444 35.7334C37.0222 35.7334 27.9556 42.089 22.1778 50.489C18.6222 55.6445 16.9333 61.0668 17.6889 65.0223C18.1333 67.3334 19.3778 69.2001 21.4222 70.5779C24.5333 72.6668 27.6 73.4668 30.5778 72.889C35.7333 71.8668 38.9333 67.1557 38.9333 67.0668C39.1556 66.7112 39.5556 66.489 40 66.489Z"
        fill={color}
      />
      <path
        d="M66.9333 45.8668C65.8667 45.8668 64.8 45.6001 63.8222 45.1112C62 44.1334 60.8 42.7557 60.3111 41.0223C59.6444 38.7112 60.2667 35.9557 62.1333 32.7112C63.6889 30.0001 66.0889 28.089 68.6667 27.3779C70.4889 26.889 72.2222 27.1112 73.7333 27.9557C77.9111 30.4001 78.5333 34.9334 75.3778 40.3112C73.3333 43.8223 70.0444 45.8668 66.9333 45.8668ZM70.5778 29.7779C70.1333 29.7779 69.6889 29.8668 69.3778 29.9557C67.5111 30.4446 65.6889 31.9557 64.4889 34.0446C63.0222 36.5779 62.4889 38.7112 62.9333 40.2668C63.2444 41.289 63.9556 42.089 65.1556 42.7557C67.6 44.089 71.1556 42.4001 73.1111 39.0223C74.7556 36.1779 75.9555 32.3557 72.4 30.2668C71.7778 29.9112 71.1556 29.7779 70.5778 29.7779Z"
        fill={color}
      />
      <path
        d="M13.0667 45.8668C9.95555 45.8668 6.66666 43.8223 4.62222 40.3112C1.51111 34.889 2.08888 30.4001 6.26666 27.9557C7.73333 27.1112 9.46666 26.889 11.2889 27.3779C13.8667 28.0446 16.2667 30.0001 17.8222 32.7112C19.6889 35.9112 20.2667 38.7112 19.6444 41.0223C19.1556 42.7557 17.9556 44.1334 16.1333 45.1112C15.2 45.6001 14.1333 45.8668 13.0667 45.8668ZM6.93333 38.9779C8.88888 42.3557 12.4444 44.0446 14.8889 42.7557C16.0889 42.089 16.8 41.289 17.1111 40.2668C17.5556 38.7112 17.0222 36.5779 15.5556 34.0446C14.3556 32.0001 12.5333 30.4446 10.6667 29.9557C9.86666 29.7334 8.71111 29.6446 7.64444 30.2668C4.08888 32.3112 5.28888 36.1334 6.93333 38.9779Z"
        fill={color}
      />
      <path
        d="M49.6 26.8889C48.9778 26.8889 48.3111 26.8001 47.6889 26.6667C45.2889 26.0889 43.5555 24.8889 42.4889 23.0223C41.0667 20.5334 40.9778 17.0223 42.2667 12.8445C44.0889 6.97783 49.2444 3.37783 54.3111 4.40005C56.4 4.8445 58.0889 6.0445 59.1556 7.86672C60.7111 10.4889 60.9333 14.3556 59.7333 18.2223C58.1333 23.4667 54 26.8889 49.6 26.8889ZM52.5333 6.93339C49.2444 6.93339 46.0444 9.6445 44.8 13.6445C43.7333 17.0667 43.7333 19.9112 44.7555 21.6889C45.4222 22.8889 46.5778 23.6445 48.2667 24.0445C51.8667 24.8889 55.7778 22.0001 57.1556 17.4223C58.1333 14.2667 58 11.2445 56.8 9.2445C56.0889 8.0445 55.0667 7.28894 53.6889 7.02227C53.3778 6.97783 52.9333 6.93339 52.5333 6.93339Z"
        fill={color}
      />
      <path
        d="M27.4222 28.1778C23.0222 28.1778 18.8889 24.7556 17.2889 19.5556C16.0889 15.7334 16.3111 11.8667 17.8667 9.20004C18.9333 7.37782 20.6222 6.17782 22.7111 5.73338C27.7778 4.66671 32.9333 8.31116 34.7556 14.1778C36.0444 18.3556 35.9556 21.8667 34.5333 24.3556C33.4667 26.2223 31.7333 27.4223 29.3333 28C28.7111 28.1334 28.0444 28.1778 27.4222 28.1778ZM24.4889 8.22227C24.0889 8.22227 23.6889 8.26671 23.2444 8.3556C21.9111 8.62227 20.8444 9.37782 20.1333 10.5778C18.9333 12.5778 18.8444 15.6445 19.7778 18.7556C21.2 23.2889 25.1111 26.2223 28.6667 25.3778C30.3111 24.9778 31.4667 24.2223 32.1778 23.0223C33.2 21.2445 33.2 18.3556 32.1333 14.9778C30.9778 10.9334 27.7778 8.22227 24.4889 8.22227Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationPaw;
