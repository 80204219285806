import getBasePath from '@/lib/utils/getBasePath/getBasePath';
import generateImageUrl from '@/lib/utils/helpers/photoHelpers/generateImageUrl';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import Head from 'next/head';
import { useRouter } from 'next/router';

/**
 * Function that takes an image URL and returns a formatted image URL with
 * specific parameters if it includes 'images.ctfassets.net'. Otherwise, it
 * generates a new image URL.
 *
 * @param {string} image - The original image URL
 * @returns {string} The formatted image URL
 */
function getFormattedImage(image: string): string {
  if (image.includes('images.ctfassets.net')) {
    return addHttps(`${image}?w=1200&h=630`);
  }
  return generateImageUrl(`${image}?width=1200&height=630`);
}

/**
 * Interface for our Head component
 *
 * @interface
 */
interface IHead {
  /** Title to be used in the Head */
  title: string;
  /** Description to be used in the Head */
  description?: string;
  /** The optional page image */
  image?: string;
  /**
   * No index will not allow the page to be indexed by search engines
   *
   * @default false
   */
  noIndex?: boolean;
}

/**
 * Head Component to be used in the Head of the page
 *
 * @param {IHead} params - The params for the Head component
 * @returns {React.FC<IHead>} Head Component
 */
const HeadTemplate: React.FC<IHead> = ({
  title,
  description,
  image,
  noIndex = false,
}) => {
  const router = useRouter();

  /**
   * Determine the canonical path while removing the search params
   *
   * @returns {string} - The canonical path
   */
  const canonicalPath = () => {
    if (router.asPath.includes('?')) {
      return (
        process.env.NEXT_PUBLIC_BASE_URL +
        getBasePath() +
        router.asPath.substring(0, router.asPath.indexOf('?'))
      );
    }
    return process.env.NEXT_PUBLIC_BASE_URL + getBasePath() + router.asPath;
  };

  /**
   * Page Title with the site name appended to the end
   *
   * @constant {string}
   */
  const pageTitle = title.includes(' | Petco Love Lost')
    ? title
    : `${title} | Petco Love Lost`;

  const basePath = getBasePath();

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} key="title" />
      <meta property="og:site_name" content="Petco Love Lost" key="site_name" />
      {description && (
        <meta name="description" content={description} key="description" />
      )}
      {description && (
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />
      )}

      {image && (
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={getFormattedImage(image)} />
          <meta property="twitter:image" content={getFormattedImage(image)} />
          <meta
            property="og:image"
            content={getFormattedImage(image)}
            key="og:image"
          />
        </>
      )}

      {noIndex && <meta name="robots" content="noindex,nofollow" />}
      <link rel="icon" href={`${basePath}/images/favicon.ico`} sizes="any" />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`${basePath}/images/apple-touch-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${basePath}/images/apple-touch-icon-152x152.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href={`${basePath}/images/apple-touch-icon-167x167.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${basePath}/images/apple-touch-icon-180x180.png`}
      />
      <link rel="canonical" href={canonicalPath()} />
    </Head>
  );
};

export default HeadTemplate;
