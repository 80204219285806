import CdnImage from '@/components/atoms/CdnImage/CdnImage';
import Icon, { IIcon } from '@/components/atoms/Icon/Icon';

/**
 * ILoadingSpinner - Interface for LoadingSpinner component
 *
 * @interface ILoadingSpinner
 */
export interface ILoadingSpinner {
  /**
   * The optional classes for the component.
   *
   * @memberof ILoadingSpinner
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * The optional size of the spinner.
   *
   * @memberof ILoadingSpinner
   * @default 32
   * @member {number} [size]
   */
  size?: number;
  /**
   * The optional icon to be used in the spinner.
   *
   * @memberof ILoadingSpinner
   * @member {IIcon} [icon]
   */
  icon?: IIcon;
}

/**
 * Loading Spinner Spinning Loading SVG to be used when loading data.
 *
 * @param {ILoadingSpinner} props - The props for the LoadingSpinner component
 * @returns {React.FC<ILoadingSpinner>} Loading Spinner Component
 */
const LoadingSpinner: React.FC<ILoadingSpinner> = ({
  classes = '',
  size = 32,
  icon,
}: ILoadingSpinner) => {
  return (
    <div data-testid="loading-spinner" className={classes}>
      {icon ? (
        <Icon {...icon} classes="animate-spin-loader" />
      ) : (
        <CdnImage
          className="animate-spin-loader"
          src={'/assets/lost/load.svg'}
          alt="loading"
          layout="fixed"
          width={size}
          height={size}
        />
      )}
    </div>
  );
};

export default LoadingSpinner;
