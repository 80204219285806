import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconFillUpload: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-fill-upload"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4594 11.0145C19.5969 10.6405 19.7 10.2665 19.7 9.82457C19.7 8.02272 18.2219 6.56083 16.4 6.56083C15.7125 6.56083 15.0594 6.76482 14.5438 7.13879C13.6156 5.50692 11.8281 4.38501 9.8 4.38501C6.74063 4.38501 4.3 6.83281 4.3 9.82457C4.3 9.92656 4.3 10.0286 4.3 10.1305C2.375 10.7765 1 12.6123 1 14.7202C1 17.44 3.2 19.6158 5.95 19.6158H18.6C21.0063 19.6158 23 17.6779 23 15.2641C23 13.1903 21.4875 11.4224 19.4594 11.0145ZM8.39063 11.9664L11.4156 8.97464C11.4844 8.90664 11.6906 8.73666 12 8.73666C12.275 8.73666 12.4813 8.90664 12.55 9.00864L15.575 12.0004C15.9188 12.3064 15.9188 12.8163 15.575 13.1223C15.2656 13.4623 14.75 13.4623 14.4406 13.1223L12.825 11.5244V16.624C12.825 17.1 12.4469 17.44 12 17.44C11.5188 17.44 11.175 17.1 11.175 16.624V11.5244L9.525 13.1223C9.18125 13.4283 8.66563 13.4283 8.35625 13.1223C8.0125 12.7823 8.04688 12.3064 8.39063 11.9664Z"
        fill={color}
      />
    </svg>
  );
};

export default IconFillUpload;
