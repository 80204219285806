import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineTrash: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-trash"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6786 7.25C18.375 7.25 18.1094 7.50977 18.0714 7.80664L17.0089 19.793C16.971 20.0898 16.7054 20.3125 16.4018 20.3125H7.56027C7.2567 20.3125 6.99107 20.0898 6.99107 19.793L5.89062 7.80664C5.85268 7.50977 5.58705 7.25 5.28348 7.25C4.90402 7.25 4.63839 7.58398 4.67634 7.91797L5.77679 19.9043C5.85268 20.7949 6.64955 21.5 7.56027 21.5H16.4018C17.3504 21.5 18.1094 20.7949 18.1853 19.9043L19.2857 7.91797C19.3237 7.58398 19.058 7.25 18.6786 7.25ZM19.8929 4.875H16.25L14.9598 3.24219C14.6183 2.79688 14.0871 2.5 13.5179 2.5H10.4821C9.875 2.5 9.34375 2.79688 9.00223 3.24219L7.75 4.875H4.10714C3.76562 4.875 3.5 5.17188 3.5 5.46875C3.5 5.80273 3.76562 6.0625 4.10714 6.0625H19.8929C20.1964 6.0625 20.5 5.80273 20.5 5.46875C20.5 5.17188 20.1964 4.875 19.8929 4.875ZM9.26786 4.875L9.98884 3.94727C10.1027 3.79883 10.2924 3.6875 10.4821 3.6875H13.5179C13.7076 3.6875 13.8594 3.79883 13.9732 3.94727L14.7321 4.875H9.26786Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineTrash;
