import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineChevronRightCircled: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-outline-chevron-right-circled',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-chevron-right-circled"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke={color} />
        <path
          d="M9.68173 5.51563L15.4005 11.4844C15.5255 11.6406 15.6192 11.8281 15.6192 12.0156C15.6192 12.2031 15.5255 12.3906 15.4005 12.5156L9.68173 18.4844C9.40048 18.7969 8.90048 18.7969 8.61923 18.5156C8.30673 18.2344 8.30673 17.7656 8.58798 17.4531L13.838 11.9844L8.58798 6.54688C8.30673 6.26563 8.30673 5.76563 8.61923 5.48438C8.90048 5.20313 9.40048 5.20313 9.68173 5.51563Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconOutlineChevronRightCircled;
