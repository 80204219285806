import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineCloseCircle: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-outline-close-circle',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-close-circle"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M12 5C10.6155 5 9.26215 5.41054 8.11101 6.17971C6.95987 6.94888 6.06266 8.04213 5.53285 9.32121C5.00303 10.6003 4.86441 12.0078 5.13451 13.3656C5.4046 14.7235 6.07129 15.9708 7.05026 16.9497C8.02922 17.9287 9.2765 18.5954 10.6344 18.8655C11.9922 19.1356 13.3997 18.997 14.6788 18.4672C15.9579 17.9373 17.0511 17.0401 17.8203 15.889C18.5895 14.7378 19 13.3845 19 12C19 11.0807 18.8189 10.1705 18.4672 9.32121C18.1154 8.47194 17.5998 7.70026 16.9497 7.05025C16.2997 6.40024 15.5281 5.88463 14.6788 5.53284C13.8295 5.18106 12.9193 5 12 5ZM12 17.6C10.8924 17.6 9.80972 17.2716 8.88881 16.6562C7.96789 16.0409 7.25013 15.1663 6.82628 14.143C6.40243 13.1198 6.29153 11.9938 6.50761 10.9075C6.72368 9.8212 7.25703 8.82337 8.0402 8.0402C8.82338 7.25703 9.8212 6.72368 10.9075 6.5076C11.9938 6.29153 13.1198 6.40242 14.143 6.82627C15.1663 7.25012 16.0409 7.96789 16.6562 8.88881C17.2716 9.80972 17.6 10.8924 17.6 12C17.6 13.4852 17.01 14.9096 15.9598 15.9598C14.9096 17.01 13.4852 17.6 12 17.6Z"
          fill={color}
        />
        <path
          d="M13.897 10.103C13.8319 10.0374 13.7545 9.98531 13.6692 9.94977C13.5839 9.91423 13.4924 9.89594 13.4 9.89594C13.3076 9.89594 13.2161 9.91423 13.1308 9.94977C13.0455 9.98531 12.968 10.0374 12.903 10.103L12 11.013L11.097 10.103C10.9652 9.97118 10.7864 9.89713 10.6 9.89713C10.4136 9.89713 10.2348 9.97118 10.103 10.103C9.97115 10.2348 9.8971 10.4136 9.8971 10.6C9.8971 10.7864 9.97115 10.9652 10.103 11.097L11.013 12L10.103 12.903C10.0374 12.9681 9.98528 13.0455 9.94974 13.1308C9.9142 13.2161 9.8959 13.3076 9.8959 13.4C9.8959 13.4924 9.9142 13.5839 9.94974 13.6692C9.98528 13.7545 10.0374 13.8319 10.103 13.897C10.168 13.9626 10.2455 14.0147 10.3308 14.0502C10.4161 14.0858 10.5076 14.1041 10.6 14.1041C10.6924 14.1041 10.7839 14.0858 10.8692 14.0502C10.9545 14.0147 11.0319 13.9626 11.097 13.897L12 12.987L12.903 13.897C12.968 13.9626 13.0455 14.0147 13.1308 14.0502C13.2161 14.0858 13.3076 14.1041 13.4 14.1041C13.4924 14.1041 13.5839 14.0858 13.6692 14.0502C13.7545 14.0147 13.8319 13.9626 13.897 13.897C13.9626 13.8319 14.0146 13.7545 14.0502 13.6692C14.0857 13.5839 14.104 13.4924 14.104 13.4C14.104 13.3076 14.0857 13.2161 14.0502 13.1308C14.0146 13.0455 13.9626 12.9681 13.897 12.903L12.987 12L13.897 11.097C13.9626 11.0319 14.0146 10.9545 14.0502 10.8692C14.0857 10.7839 14.104 10.6924 14.104 10.6C14.104 10.5076 14.0857 10.4161 14.0502 10.3308C14.0146 10.2455 13.9626 10.1681 13.897 10.103Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconOutlineCloseCircle;
