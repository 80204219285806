import {
  Colors,
  ColorShade,
  ColorType,
} from '@/components/atoms/Colors/Colors';
import IconList from './Icons/IconList';

/**
 * Icon Type Generates a Icon Type by getting the total keys of the IconList
 *
 * @typedef {object} IconType
 * @property {keyof typeof IconList} icon - The Type of Icon to display
 */
export type IconType = keyof typeof IconList;

/**
 * IIcon Interface for the Icon component
 *
 * @interface IIcon
 */
export type IIcon = {
  /**
   * The Type of Icon to display
   *
   * @memberof IIcon
   * @member {IconType} icon
   */
  icon: IconType;
  /**
   * The size of the icon in pixels
   *
   * @memberof IIcon
   * @member {number} size
   */
  size: number;
  /**
   * The color of the icon. Do not use with `colorInherit`.
   *
   * @memberof IIcon
   * @member {ColorType} [colorType]
   */
  colorType?: ColorType;
  /**
   * The shade of the color to use. Do not use with `colorInherit`.
   *
   * @memberof IIcon
   * @member {ColorShade} [colorShade]
   */
  colorShade?: ColorShade;
  /**
   * If you need to assign an ID to an icon. Used mainly when looping through
   * icons
   *
   * @memberof IIcon
   * @member {string} [id]
   */
  id?: string;
  /**
   * Optional classes to be applied to the component
   *
   * @memberof IIcon
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
};

/**
 * Choose Icon
 *
 * @param {IconType} icon - The icon to display.
 * @returns {Icon} Proper Icon Component to Render
 */
export const chooseIcon = (icon: IconType) => {
  const Icon = IconList[icon];
  return Icon;
};

/**
 * Icon - Icon Component that renders an Icon from the IconList
 *
 * @param {IIcon} props - The props of the Icon Component
 * @returns {React.FC<IIcon>} Icon Component
 */
const Icon: React.FC<IIcon> = ({
  icon,
  size,
  colorType,
  colorShade,
  id,
  classes = '',
}: IIcon) => {
  let color = 'currentColor';

  if (colorType && colorShade) {
    color = Colors[colorType][colorShade] as string;
  }

  const Icon = chooseIcon(icon);

  return (
    <div data-testid={`icon-${icon}`} className={classes}>
      <Icon size={size} color={color} id={id} />
    </div>
  );
};

export default Icon;
