import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineGlobe: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-globe"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3592_16281)">
        <path
          d="M19 12C19 11.0807 18.8189 10.1705 18.4672 9.32122C18.1154 8.47194 17.5998 7.70026 16.9497 7.05025C16.2997 6.40024 15.5281 5.88463 14.6788 5.53284C13.8295 5.18106 12.9193 5 12 5C10.1435 5 8.36301 5.7375 7.05025 7.05025C5.7375 8.36301 5 10.1435 5 12C5 13.8565 5.7375 15.637 7.05025 16.9497C8.36301 18.2625 10.1435 19 12 19C13.8565 19 15.637 18.2625 16.9497 16.9497C18.2625 15.637 19 13.8565 19 12ZM17.551 11.3H15.5C15.3846 9.6537 14.8205 8.07045 13.869 6.722C14.8465 7.06918 15.7084 7.68105 16.3585 8.48939C17.0087 9.29773 17.4215 10.2708 17.551 11.3ZM9.956 12.7H14.1C13.9209 14.4294 13.1837 16.0535 12 17.327C10.817 16.0589 10.0968 14.4285 9.956 12.7ZM9.956 11.3C10.119 9.57928 10.8363 7.958 12 6.68C13.1933 7.94429 13.9321 9.56963 14.1 11.3H9.956ZM10.208 6.701C9.25148 8.05692 8.67836 9.6457 8.549 11.3H6.449C6.58123 10.258 7.00383 9.27429 7.66852 8.46106C8.33321 7.64784 9.2132 7.03793 10.208 6.701ZM6.449 12.7H8.549C8.66428 14.3529 9.23352 15.9419 10.194 17.292C9.20278 16.9534 8.32639 16.3436 7.66439 15.5319C7.00239 14.7202 6.58131 13.7391 6.449 12.7ZM13.834 17.285C14.7919 15.9345 15.3675 14.3504 15.5 12.7H17.565C17.4326 13.7362 17.0131 14.7147 16.3537 15.5249C15.6944 16.3351 14.8216 16.9448 13.834 17.285Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconOutlineGlobe;
