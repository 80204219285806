import { ReactElement, ReactNode } from 'react';

/**
 * IParagraph - Interface for Paragraph component
 *
 * @augments React.HTMLAttributes<HTMLParagraphElement>
 * @interface IParagraph
 */
export interface IParagraph extends React.HTMLAttributes<HTMLParagraphElement> {
  /**
   * The styling of the paragraph. Regular, bold, or italic or boldItalic.
   *
   * @memberof IParagraph
   * @default 'regular'
   * @member {'regular' | 'bold' | 'italic' | 'boldItalic'} [styling]
   */
  styling?: 'regular' | 'bold' | 'italic' | 'boldItalic';
  /**
   * The size of the paragraph.
   *
   * @memberof IParagraph
   * @default 'body1'
   * @member {string} [size]
   */
  size?:
    | 'body1'
    | 'body2'
    | 'body3'
    | 'body4'
    | 'body5'
    | 'body-article'
    | 'overline';
  /**
   * The font of the paragraph.
   *
   * @memberof IParagraph
   * @default 'petco'
   * @member {'petco' | 'amasis'} [font]
   */
  font?: 'petco' | 'amasis';
  /**
   * The content of the paragraph.
   *
   * @memberof IParagraph
   * @member {ReactElement | string | ReactNode} children
   */
  children?: ReactElement | string | ReactNode;
}

/**
 * Paragraph Used to display a paragraph of text. Can be used as a generic
 * paragraph.
 *
 * @param {IParagraph} props - The props for the Paragraph component
 * @returns {React.FC<IParagraph>} Paragraph Component
 */
const Paragraph: React.FC<IParagraph> = ({
  styling = 'regular',
  size = 'body1',
  font = 'petco',
  children,
  className = '',
  ...rest
}: IParagraph) => {
  // Set the styling Classes
  let stylingClass = '';
  switch (styling) {
    case 'bold':
      stylingClass = 'font-bold';
      break;
    case 'italic':
      stylingClass = 'italic';
      break;
    case 'boldItalic':
      stylingClass = 'font-bold italic';
      break;
    default:
      stylingClass = '';
      break;
  }

  const paragraphClass = `text-${size} font-${font} break-words ${stylingClass} ${className}`;
  return (
    <p data-testid="paragraph" {...rest} className={paragraphClass}>
      {children}
    </p>
  );
};

export default Paragraph;
