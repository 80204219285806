import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationBrokenHeart: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-broken-heart',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-broken-heart"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.0889 73.3333C36.8444 73.3333 26.3556 67.0667 18.8444 60.6667C10.5778 53.6889 1.82222 44.9778 1.77778 30.4889C1.77778 21.7333 5.33333 16.1778 8.31111 13.0667C12.1333 9.06666 17.2444 6.75555 22.3111 6.75555C22.3111 6.75555 22.3111 6.75555 22.3556 6.75555C31.5556 6.75555 37.6444 13.6444 40.0444 16.9778C40.8889 15.8667 42.1778 14.3111 43.8667 12.7111C48.0889 8.79999 52.8889 6.71111 57.6889 6.71111C57.6889 6.71111 57.6889 6.71111 57.7333 6.71111C62.8 6.71111 67.9111 8.97777 71.7333 12.9778C74.7111 16.0889 78.3111 21.6444 78.3111 30.4C78.3556 44.8889 69.5556 53.6 61.3778 60.6667C54.2667 66.6222 43.7333 73.3333 40.0889 73.3333ZM22.3111 9.37777C13.5111 9.42222 4.44445 17.2889 4.44445 30.4889C4.44445 43.8222 12.7556 52.0444 20.5333 58.6667C28.1778 65.2 37.9556 70.6667 40.0444 70.6667C42.1778 70.6667 51.5111 65.4667 59.5111 58.5778C67.2889 51.9111 75.5556 43.6889 75.5111 30.3556C75.5111 22.4889 72.3111 17.5555 69.6889 14.8C66.3556 11.3333 61.9556 9.33333 57.6 9.33333C57.6 9.33333 57.6 9.33333 57.5556 9.33333C47.5556 9.33333 41.1111 19.9111 41.0667 20C40.8444 20.4 40.4 20.6222 39.9111 20.6667C39.4222 20.6667 39.0222 20.4 38.7556 20C38.7556 19.8667 32.6222 9.37777 22.3111 9.37777Z"
        fill={color}
      />
      <path
        d="M38.5778 50.8889C38.2667 50.8889 38 50.8 37.7333 50.6222C37.1556 50.1778 37.0667 49.3333 37.5111 48.7556L42.1778 42.8444L36.3111 36.8889C36.0444 36.6222 35.9111 36.2667 35.9111 35.9111C35.9111 35.5556 36.0889 35.2 36.3556 34.9333L44.8 27.5556L38.9333 20.1333C38.4889 19.5555 38.5778 18.7111 39.1556 18.2667C39.7333 17.8222 40.5778 17.9111 41.0222 18.4889L47.6889 26.9333C48.1333 27.5111 48.0445 28.3111 47.5111 28.7555L39.2 36.0444L44.9333 41.7778C45.4222 42.2667 45.4667 43.0222 45.0222 43.5556L39.6444 50.4C39.3778 50.7111 38.9778 50.8889 38.5778 50.8889Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationBrokenHeart;
