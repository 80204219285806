import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineAlert: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-outline-alert',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-alert"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M12.0195 3.25C8.85547 3.25 6.00391 4.92969 4.44141 7.625C2.83984 10.3594 2.83984 13.6797 4.44141 16.375C6.00391 19.1094 8.85547 20.75 12.0195 20.75C15.1445 20.75 17.9961 19.1094 19.5586 16.375C21.1602 13.6797 21.1602 10.3594 19.5586 7.625C17.9961 4.92969 15.1445 3.25 12.0195 3.25ZM12.0195 22C8.42578 22 5.14453 20.125 3.34766 17C1.55078 13.9141 1.55078 10.125 3.34766 7C5.14453 3.91406 8.42578 2 12.0195 2C15.5742 2 18.8555 3.91406 20.6523 7C22.4492 10.125 22.4492 13.9141 20.6523 17C18.8555 20.125 15.5742 22 12.0195 22ZM12.0195 7C12.332 7 12.6445 7.3125 12.6445 7.625V12.625C12.6445 12.9766 12.332 13.25 12.0195 13.25C11.668 13.25 11.3945 12.9766 11.3945 12.625V7.625C11.3945 7.3125 11.668 7 12.0195 7ZM12.957 15.75C12.957 16.2969 12.5273 16.6875 12.0195 16.6875C11.4727 16.6875 11.082 16.2969 11.082 15.75C11.082 15.2422 11.4727 14.8125 12.0195 14.8125C12.5273 14.8125 12.957 15.2422 12.957 15.75Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconOutlineAlert;
