import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlinePlayCircle: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-play-circle"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8125 11.5625C17.5 11.4062 17.1875 11.25 16.875 11.25C15.7812 11.25 15 12.1875 15 13.125V26.875C15 27.8906 15.7812 28.75 16.875 28.75C17.1875 28.75 17.5 28.6719 17.8125 28.5156L29.0625 21.6406C29.6094 21.3281 30 20.7031 30 20C30 19.375 29.6094 18.75 29.0625 18.4375L17.8125 11.5625ZM17.5 25.7812V14.2969L26.875 20.0781L17.5 25.7812ZM20 0C8.90625 0 0 8.98438 0 20C0 31.0938 8.90625 40 20 40C31.0156 40 40 31.0938 40 20C40 8.98438 31.0156 0 20 0ZM20 37.5C10.3125 37.5 2.5 29.6875 2.5 20C2.5 10.3906 10.3125 2.5 20 2.5C29.6094 2.5 37.5 10.3906 37.5 20C37.5 29.6875 29.6094 37.5 20 37.5Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlinePlayCircle;
