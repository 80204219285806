import IIconType from '../IIconType';

/**
 * @param {IIconType} props - The icon props
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineDownload: React.FC<IIconType> = ({
  color,
  size,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      data-testid="icon-outline-download"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7712 11.36C18.6872 11.2588 18.5841 11.1751 18.4678 11.1137C18.3514 11.0524 18.2241 11.0146 18.0931 11.0025C17.9622 10.9904 17.8301 11.0043 17.7045 11.0433C17.5789 11.0824 17.4622 11.1458 17.3612 11.23L13.0012 14.86L13.0012 3.00002C13.0012 2.73481 12.8958 2.48045 12.7083 2.29291C12.5207 2.10538 12.2664 2.00002 12.0012 2.00002C11.736 2.00002 11.4816 2.10538 11.2941 2.29291C11.1065 2.48045 11.0012 2.73481 11.0012 3.00002L11.0012 14.86L6.64117 11.23C6.54005 11.146 6.42337 11.0827 6.29779 11.0437C6.1722 11.0048 6.04018 10.9909 5.90925 11.003C5.77831 11.0151 5.65104 11.0528 5.5347 11.1141C5.41835 11.1753 5.31522 11.2589 5.23117 11.36C5.14712 11.4611 5.08382 11.5778 5.04487 11.7034C5.00591 11.829 4.99208 11.961 5.00415 12.0919C5.01622 12.2229 5.05396 12.3501 5.11522 12.4665C5.17648 12.5828 5.26005 12.686 5.36117 12.77L11.3612 17.77L11.5112 17.86L11.6412 17.93C11.8728 18.0194 12.1295 18.0194 12.3612 17.93L12.4912 17.86L12.6412 17.77L18.6412 12.77C18.7424 12.6861 18.8261 12.583 18.8875 12.4666C18.9488 12.3503 18.9866 12.223 18.9987 12.092C19.0108 11.961 18.9969 11.8289 18.9579 11.7033C18.9188 11.5777 18.8554 11.4611 18.7712 11.36Z"
        fill={color}
      />
      <rect x="5" y="19.9971" width="14" height="2" rx="1" fill={color} />
    </svg>
  );
};

export default IconOutlineDownload;
