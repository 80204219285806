import dynamic from 'next/dynamic';

const PrimaryLayout = dynamic(
  import('@/components/layouts/PrimaryLayout/PrimaryLayout')
);
const ProviderLayout = dynamic(
  import('@/components/layouts/ProviderLayout/ProviderLayout')
);

import ErrorBoundary from '@/components/templates/ErrorBoundary/ErrorBoundary';
import GoogleTagManagerTemplate from '@/components/templates/GoogleTagManagerTemplate/GoogleTagManagerTemplate';
import '@/styles/globals.css';
import type { NextComponentType } from 'next';
import { AppContext, AppInitialProps, AppLayoutProps } from 'next/app';

/**
 * @param {AppLayoutProps} context - The App Props
 * @returns {AppLayoutProps} - The User Provider
 */
export const App: NextComponentType<
  AppContext,
  AppInitialProps,
  AppLayoutProps
> = ({ Component, pageProps }: AppLayoutProps) => {
  return (
    <>
      <GoogleTagManagerTemplate />
      <ProviderLayout>
        {Component.getLayout ? (
          Component.getLayout(
            <ErrorBoundary>
              <Component {...pageProps} />
            </ErrorBoundary>
          )
        ) : (
          <PrimaryLayout>
            <ErrorBoundary>
              <Component {...pageProps} />
            </ErrorBoundary>
          </PrimaryLayout>
        )}
      </ProviderLayout>
    </>
  );
};

export default App;
