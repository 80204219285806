import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineLightBulb: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-light-bulb"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.89773 19.5298C9.89773 19.7407 9.93182 19.9517 10.0682 20.1274L10.6477 21.0415C10.8182 21.3228 11.2273 21.5337 11.5682 21.5337H13.6477C13.9886 21.5337 14.3977 21.3228 14.5682 21.0415L15.1477 20.1274C15.25 19.9868 15.3182 19.7056 15.3182 19.5298L15.3523 18.1587H9.89773V19.5298ZM12.625 3.53369C9.14773 3.56885 6.625 6.45166 6.625 9.72119C6.625 11.2681 7.17045 12.6743 8.09091 13.7642C8.67045 14.4321 9.52273 15.8384 9.86364 16.9985C9.86364 16.9985 9.86364 16.9985 9.86364 17.0337H15.3523C15.3523 16.9985 15.3523 16.9985 15.3523 16.9985C15.6932 15.8384 16.5455 14.4321 17.125 13.7642C18.0455 12.7095 18.625 11.3032 18.625 9.72119C18.625 6.31104 15.9318 3.56885 12.625 3.53369ZM15.8977 12.7095C15.3523 13.3071 14.7045 14.3267 14.2273 15.3462H10.9886C10.5114 14.3267 9.86364 13.3071 9.31818 12.7095C8.63636 11.8657 8.26136 10.811 8.26136 9.72119C8.26136 7.5415 9.89773 5.25635 12.5909 5.22119C15.0114 5.22119 16.9886 7.26025 16.9886 9.72119C16.9886 10.811 16.5795 11.8657 15.8977 12.7095ZM12.0795 6.34619C10.5455 6.34619 9.35227 7.61182 9.35227 9.15869C9.35227 9.4751 9.59091 9.72119 9.89773 9.72119C10.1705 9.72119 10.4432 9.4751 10.4432 9.15869C10.4432 8.24463 11.1591 7.47119 12.0795 7.47119C12.3523 7.47119 12.625 7.2251 12.625 6.94385C12.625 6.62744 12.3523 6.34619 12.0795 6.34619Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineLightBulb;
