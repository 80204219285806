import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineInfo: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-social-media-nextdoor"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2655 5C11.7468 5 9.60286 6.15539 8.39913 7.92472C8.30002 8.07217 8.12841 8.3864 7.89878 8.38881C6.79657 8.39848 6.72889 7.04006 6.69505 5.82907C6.69022 5.65021 6.53552 5.50518 6.35907 5.50518L3.16846 5.48826C2.97993 5.48826 2.82523 5.64296 2.83007 5.83149C2.90016 8.79006 3.44402 10.7987 7.02862 11.5988C7.2244 11.6423 7.36218 11.8211 7.36218 12.0218C7.36218 13.2497 7.36218 17.4434 7.36218 18.664C7.36218 18.8501 7.51204 19 7.70058 19H10.7993C10.9855 19 11.1377 18.8501 11.1377 18.6616V11.8018C11.1377 10.2766 12.2182 8.54834 14.2679 8.54834C16.2209 8.54834 17.3981 10.279 17.3981 11.8018V18.6616C17.3981 18.8477 17.548 19 17.7365 19H20.8352C21.0214 19 21.1736 18.8501 21.1736 18.6616V11.2918C21.1688 7.76761 18.1595 5 14.2655 5Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineInfo;
