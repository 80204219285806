import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color}
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineEye: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-eye"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8C9.78125 8 8 9.8125 8 12C8 14.2188 9.78125 16 12 16C14.1875 16 16 14.2188 16 12.0312C16 9.8125 14.1875 8 12 8ZM12 15C10.3438 15 9 13.6562 9 12C9 10.375 10.3438 9 12 9C13.625 9 15 10.375 15 12.0312C15 13.6562 13.625 15 12 15ZM20.875 11.4688C19.1875 7.625 15.8125 5 12 5C8.15625 5 4.78125 7.625 3.09375 11.4688C3.03125 11.625 3 11.8438 3 12C3 12.1562 3.03125 12.4062 3.09375 12.5625C4.78125 16.4062 8.15625 19 12 19C15.8125 19 19.1875 16.4062 20.875 12.5625C20.9375 12.4062 21 12.1562 21 12C21 11.8438 20.9375 11.625 20.875 11.4688ZM19.9688 12.1562C18.375 15.75 15.3125 18 12 18C8.65625 18 5.59375 15.7812 4.03125 12.1562C4 12.125 4 12.0312 3.96875 12.0312C3.96875 12 3.96875 11.9062 3.96875 11.875C5.59375 8.25 8.65625 6 12 6C15.3125 6 18.375 8.25 19.9688 11.875C19.9688 11.9062 19.9688 12 19.9688 12C19.9688 12.0312 19.9688 12.125 19.9688 12.1562Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineEye;
