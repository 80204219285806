import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationVaccinatedPaw: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-vaccinated-paw',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-vaccinated-paw"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.8445 78.6667C24.8 78.6667 24.7111 78.6667 24.6667 78.6667C23.9556 78.5778 23.4222 77.9111 23.5111 77.1556C24.6667 68.1778 24.4 59.6 24.3556 59.2444V59.2C24.3556 45.2889 23.0667 31.5556 20.9778 23.3778C19.7778 18.6667 20.1778 15.0222 22.1778 12.5778C23.5111 10.9778 25.2 10.2667 26.4889 9.95555C27.1111 6.04444 28.7111 3.42222 31.2445 2.17778C34.4889 0.533332 38.0889 1.73333 40 2.62222C41.8667 1.73333 45.5111 0.533332 48.7556 2.17778C51.2445 3.46667 52.8445 6.08889 53.5111 9.95555C54.8 10.2667 56.4889 10.9333 57.8222 12.5778C59.8222 15.0222 60.2222 18.6667 59.0222 23.3778C56.9334 31.5556 55.6445 45.2889 55.6445 59.2C55.6445 59.5111 55.8667 67.9111 57.1556 77.1556C57.2445 77.8667 56.7556 78.5778 56 78.6667C55.2889 78.7556 54.5778 78.2667 54.4889 77.5111C53.1556 68.1333 52.9333 59.6 52.9333 59.2444C52.9333 45.1111 54.2667 31.1556 56.4 22.7556C57.3778 18.8889 57.1556 16.0444 55.7334 14.3111C54.6222 12.9333 53.0222 12.5778 52.1778 12.4889C51.5556 12.4444 51.0667 11.9556 50.9778 11.3333C50.5778 7.82222 49.4222 5.55555 47.5556 4.62222C44.9333 3.28889 41.6 4.84444 40.6667 5.33333C40.2667 5.55555 39.8222 5.55555 39.4222 5.33333C38.4445 4.84444 35.1111 3.28889 32.5333 4.62222C30.6667 5.55555 29.5111 7.82222 29.1111 11.3333C29.0222 11.9556 28.5333 12.4444 27.9111 12.4889C27.0667 12.5778 25.4667 12.9333 24.3556 14.3111C22.9334 16.0444 22.7111 18.8889 23.6889 22.7556C25.8222 31.1556 27.1556 45.1111 27.1556 59.2C27.1556 59.8222 27.4667 68.4889 26.2667 77.5556C26.0889 78.1778 25.5111 78.6667 24.8445 78.6667Z"
        fill={color}
      />
      <path
        d="M54.6222 67.3778C54.4445 67.3778 54.2667 67.3333 54.0889 67.2444L39.5556 60.7111C39.2445 60.5778 36.8889 59.5111 35.9556 57.2C35.3778 55.7333 35.4667 54.0889 36.2222 52.3556C36.9778 50.6222 38.1333 49.5111 39.6445 48.9778C42 48.1778 44.3556 49.2444 44.7111 49.4222L54.7111 53.9556C55.3778 54.2667 55.6889 55.0667 55.3778 55.7333C55.0667 56.4 54.2667 56.7111 53.6 56.4L43.5556 51.8667C43.5556 51.8667 43.5111 51.8667 43.5111 51.8222C43.5111 51.8222 41.9111 51.0222 40.4889 51.5111C39.6889 51.7778 39.1111 52.4444 38.6222 53.4667C38.1334 54.5333 38.0889 55.4222 38.4 56.2222C38.9778 57.6 40.5333 58.2667 40.5333 58.2667H40.5778L55.1111 64.8444C55.7778 65.1556 56.0889 65.9556 55.7778 66.6222C55.6 67.1111 55.1556 67.3778 54.6222 67.3778Z"
        fill={color}
      />
      <path
        d="M54.3556 67.2889C54.1778 67.2889 54 67.2444 53.8222 67.1556L46.5778 63.8667C45.9111 63.5556 45.6 62.7556 45.9111 62.0889L49.9556 53.2C50.0889 52.8889 50.3556 52.6222 50.7111 52.4889C51.0222 52.3556 51.4222 52.3556 51.7333 52.5333L54.8445 53.9556C55.5111 54.2667 55.8222 55.0667 55.5111 55.7333C55.2 56.4 54.4 56.7111 53.7333 56.4L51.8222 55.5556L48.8889 62.0444L54.9333 64.8C55.6 65.1111 55.9111 65.9111 55.6 66.5778C55.3333 66.9778 54.8445 67.2889 54.3556 67.2889Z"
        fill={color}
      />
      <path
        d="M46.1334 55.1111C46.1778 55.2889 46.3112 55.4222 46.4889 55.5111C46.8445 55.6889 47.2445 55.5111 47.4223 55.1555C47.6 54.8 47.4223 54.4 47.0667 54.2222C46.8889 54.1333 46.7112 54.1333 46.5334 54.2222C46.3556 54.2667 46.2223 54.4 46.1334 54.5778C46.0445 54.7555 46.0445 54.9778 46.1334 55.1111Z"
        fill={color}
      />
      <path
        d="M46.5778 56.9778C46.7556 56.6222 46.5778 56.2222 46.2223 56.0444C45.8667 55.8667 45.4667 56.0444 45.289 56.4C45.2001 56.5778 45.2001 56.7556 45.289 56.9333C45.3334 57.1111 45.4667 57.2444 45.6445 57.3333C45.8223 57.4222 46.0001 57.4222 46.1778 57.3333C46.3556 57.2889 46.489 57.1556 46.5778 56.9778Z"
        fill={color}
      />
      <path
        d="M45.7334 58.8444C45.8223 58.6667 45.8223 58.4889 45.7334 58.3111C45.6889 58.1333 45.5556 58 45.3778 57.9111C45.2 57.8222 45.0223 57.8222 44.8445 57.9111C44.6667 57.9556 44.5334 58.0889 44.4445 58.2667C44.3556 58.4445 44.3556 58.6222 44.4445 58.8C44.4889 58.9778 44.6223 59.1111 44.8 59.2C45.1556 59.3333 45.5556 59.1556 45.7334 58.8444Z"
        fill={color}
      />
      <path
        d="M43.4222 54.9778C43.5111 55.1556 43.6 55.2889 43.7778 55.3778C44.1333 55.5556 44.5333 55.3778 44.7111 55.0222C44.8889 54.6667 44.7111 54.2667 44.3556 54.0889C44.1778 54 44 54 43.8222 54.0889C43.6445 54.1333 43.5111 54.2667 43.4222 54.4444C43.3778 54.6222 43.3333 54.8 43.4222 54.9778Z"
        fill={color}
      />
      <path
        d="M43.8667 56.8444C44.0445 56.4889 43.8667 56.0889 43.5112 55.9111C43.1556 55.7333 42.7556 55.9111 42.5778 56.2667C42.4889 56.4444 42.4889 56.6222 42.5778 56.8C42.6223 56.9778 42.7556 57.1111 42.9334 57.2C43.2889 57.3778 43.6889 57.2 43.8667 56.8444Z"
        fill={color}
      />
      <path
        d="M42.8445 52.5333C42.7556 52.3556 42.6667 52.2222 42.489 52.1333C42.3112 52.0444 42.1334 52.0444 41.9556 52.1333C41.7778 52.1778 41.6445 52.3111 41.5556 52.4889C41.4667 52.6667 41.4667 52.8444 41.5556 53.0222C41.6001 53.2 41.7334 53.3333 41.9112 53.4222C42.2667 53.6 42.6667 53.4222 42.8445 53.0667C42.9334 52.8889 42.9334 52.7111 42.8445 52.5333Z"
        fill={color}
      />
      <path
        d="M41.6445 53.9556C41.4667 53.8667 41.2889 53.8667 41.1111 53.9556C40.9334 54 40.8 54.1333 40.7111 54.3111C40.6222 54.4889 40.6222 54.6667 40.7111 54.8444C40.7556 55.0222 40.8889 55.1556 41.0667 55.2444C41.2445 55.3333 41.4222 55.3333 41.6 55.2444C41.7778 55.2 41.9111 55.0667 42 54.8889C42.1778 54.5333 42 54.1333 41.6445 53.9556Z"
        fill={color}
      />
      <path
        d="M41.2 56.1778C41.1556 56 41.0223 55.8667 40.8445 55.7778C40.6667 55.6889 40.4889 55.6889 40.3111 55.7778C40.1334 55.8222 40 55.9556 39.9111 56.1333C39.8223 56.3111 39.8223 56.4889 39.9111 56.6667C39.9556 56.8444 40.0889 56.9778 40.2667 57.0667C40.6223 57.2444 41.0223 57.0667 41.2 56.7111C41.2445 56.5778 41.2445 56.3556 41.2 56.1778Z"
        fill={color}
      />
      <path
        d="M44.0889 37.0222C43.7333 37.0222 43.3778 36.9778 43.0222 36.9333C41.7333 36.6667 40.7111 36 40 35.3333C39.2889 35.9555 38.2667 36.6667 36.9778 36.9333C35.4222 37.2444 33.8667 36.8889 32.3556 35.8222C31.2444 35.0667 30.4889 33.9555 30.2667 32.6667C29.7778 29.9555 31.4222 27.2 32.1778 26.1333C34.1333 23.2889 37.8667 20.0889 40.0444 20.0889C42.3556 20.0889 45.8667 23.1555 47.9111 26.1333C48.6667 27.2 50.3111 30 49.8222 32.6667C49.6 33.9555 48.8444 35.0667 47.7333 35.8222C46.4889 36.6222 45.2889 37.0222 44.0889 37.0222ZM40 32.0444C40.4444 32.0444 40.8889 32.2667 41.1111 32.6222C41.4667 33.1111 43.3333 35.5555 46.1778 33.6444C46.7111 33.2889 47.0222 32.8 47.1556 32.2222C47.3778 31.0667 46.8 29.3333 45.6889 27.6889C43.6 24.6667 40.8444 22.8 40 22.8C39.2889 22.8444 36.3111 24.8 34.3111 27.6889C33.2 29.3333 32.6222 31.0667 32.8444 32.2222C32.9778 32.8444 33.2889 33.2889 33.8222 33.6444C36.6667 35.5555 38.5333 33.1556 38.8889 32.6222C39.1556 32.2667 39.5556 32.0444 40 32.0444Z"
        fill={color}
      />
      <path
        d="M49.6 24.4889C49.0667 24.4889 48.5778 24.3555 48.0889 24.1333C47.0222 23.5555 46.5778 22.7111 46.4 22.1333C46.0889 21.0667 46.3556 19.8667 47.1111 18.5333C47.7778 17.3778 48.8 16.5333 49.9556 16.2222C50.8445 16 51.6889 16.0889 52.4 16.5333C53.9111 17.4222 54.8889 19.3333 53.2889 22.1333C52.4889 23.6 51.0222 24.4889 49.6 24.4889ZM50.8889 18.7555C50.8 18.7555 50.7556 18.7555 50.6667 18.8C50.3111 18.8889 49.8222 19.2 49.4667 19.8667C48.8889 20.8444 48.9778 21.2889 48.9778 21.4222C48.9778 21.4667 49.0222 21.6 49.3333 21.7778C49.6889 21.9555 50.4889 21.6889 51.0222 20.7555C51.8667 19.2889 51.3333 18.9333 51.0667 18.8C51.0222 18.7555 50.9778 18.7555 50.8889 18.7555Z"
        fill={color}
      />
      <path
        d="M30.4 24.4889C28.9778 24.4889 27.5111 23.6 26.6667 22.0889C25.0667 19.2889 26.0889 17.3778 27.5556 16.4889C28.2667 16.0889 29.1556 15.9556 30 16.1778C31.1556 16.4889 32.1778 17.3333 32.8444 18.4889C33.6444 19.8222 33.8667 21.0667 33.5556 22.0889C33.3778 22.6667 32.9778 23.5111 31.8667 24.0889C31.4222 24.3556 30.9333 24.4889 30.4 24.4889ZM28.9333 20.7556C29.4667 21.6444 30.2667 21.9556 30.6222 21.7778C30.9333 21.6 30.9778 21.4667 30.9778 21.4222C31.0222 21.2889 31.0667 20.8444 30.4889 19.8667C30.1333 19.2444 29.6 18.8889 29.2889 18.8C29.1556 18.7556 28.9778 18.7556 28.8444 18.8C28.6667 18.9333 28.0889 19.2444 28.9333 20.7556Z"
        fill={color}
      />
      <path
        d="M44.3556 17.7333C44.0445 17.7333 43.7778 17.6889 43.4667 17.6444C42.0889 17.3333 41.4223 16.5333 41.0667 15.9556C40.4445 14.8444 40.4 13.3778 40.9334 11.6444C41.7334 9.06666 44 7.55555 46.2223 8C47.2 8.22222 48 8.75555 48.5334 9.64444C49.2 10.8 49.3334 12.4 48.8445 14C48.1334 16.2667 46.3112 17.7333 44.3556 17.7333ZM45.4223 10.5778C44.6223 10.5778 43.8223 11.2889 43.5112 12.3556C43.2 13.2889 43.2 14.1333 43.4223 14.5333C43.5112 14.6667 43.6445 14.8444 44.1334 14.9778C44.8889 15.1556 45.8667 14.5333 46.3112 13.2C46.5778 12.3556 46.5778 11.4667 46.2667 10.9778C46.1334 10.7556 45.9556 10.6222 45.7334 10.5778C45.6 10.6222 45.5112 10.5778 45.4223 10.5778Z"
        fill={color}
      />
      <path
        d="M35.5111 18.1778C33.5556 18.1778 31.7778 16.7111 31.0667 14.4889C30.5778 12.8889 30.6667 11.2889 31.3778 10.1333C31.9111 9.24444 32.6667 8.71111 33.6889 8.48889C35.9111 8 38.2222 9.55555 38.9778 12.0889C39.5111 13.8222 39.4667 15.2889 38.8445 16.4C38.4889 17.0222 37.8222 17.7778 36.4445 18.0889C36.0889 18.1333 35.8222 18.1778 35.5111 18.1778ZM33.6445 13.6889C34.0445 15.0222 35.0667 15.6444 35.8222 15.4667C36.2667 15.3778 36.4445 15.1556 36.5333 15.0222C36.7556 14.6222 36.7556 13.7778 36.4445 12.8444C36.0889 11.6444 35.1556 10.8889 34.2667 11.0667C34 11.1111 33.8667 11.2444 33.7333 11.4667C33.3778 11.9556 33.3778 12.8444 33.6445 13.6889Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationVaccinatedPaw;
