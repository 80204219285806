import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineImage: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-outline-image',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-outline-image"
    >
      <path
        d="M14.4258 8.5L18.5742 14.8929C18.8203 15.2143 18.8203 15.6429 18.6445 16C18.4688 16.3571 18.082 16.5714 17.6953 16.5714H6.33984C5.95312 16.5714 5.56641 16.3571 5.39062 16C5.21484 15.6071 5.25 15.1786 5.46094 14.8571L7.74609 11.6429C8.13281 11.0714 9.08203 11.0714 9.50391 11.6429L10.0664 12.4643L12.6328 8.5C13.0547 7.89286 14.0391 7.89286 14.4258 8.5ZM6.375 15.4286H17.625L13.5117 9.14286L10.5586 13.7857C10.4531 13.9286 10.2773 14.0357 10.1016 14.0357C9.92578 14.0714 9.75 13.9643 9.64453 13.7857L8.58984 12.3214L6.375 15.4286ZM18.75 4C20.0156 4 21 5.03571 21 6.28571V17.7143C21 19 19.9805 20 18.75 20H5.25C4.01953 20 3 19 3 17.7143V6.28571C3 5.03571 4.01953 4 5.25 4H18.75ZM19.9102 17.7143V6.28571C19.9102 5.67857 19.3828 5.14286 18.7852 5.14286H5.28516C4.65234 5.14286 4.16016 5.67857 4.16016 6.28571V17.7143C4.16016 18.3571 4.65234 18.8571 5.28516 18.8571H18.7852C19.3828 18.8571 19.9102 18.3571 19.9102 17.7143ZM8.09766 9.71429C7.14844 9.71429 6.41016 8.96429 6.41016 8C6.41016 7.07143 7.14844 6.28571 8.09766 6.28571C9.01172 6.28571 9.78516 7.07143 9.78516 8C9.78516 8.96429 9.01172 9.71429 8.09766 9.71429ZM8.09766 7.42857C7.78125 7.42857 7.53516 7.71429 7.53516 8C7.53516 8.32143 7.78125 8.57143 8.09766 8.57143C8.37891 8.57143 8.625 8.32143 8.625 8C8.625 7.71429 8.37891 7.42857 8.09766 7.42857Z"
        fill={color}
        id={id}
      />
    </svg>
  );
};

export default IconOutlineImage;
