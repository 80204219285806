import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineFullArrowRight: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-full-arrow-right"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8086 5.97853C12.9453 5.84181 13 5.67775 13 5.48634C13 5.32228 12.9453 5.15822 12.8086 5.0215L7.99609 0.427747C7.72266 0.181654 7.3125 0.181654 7.06641 0.455091C6.82031 0.701185 6.82031 1.13868 7.09375 1.38478L10.7305 4.83009H1.40625C1.05078 4.83009 0.75 5.13087 0.75 5.48634C0.75 5.86915 1.05078 6.14259 1.40625 6.14259H10.7305L7.09375 9.61525C6.82031 9.86134 6.82031 10.2715 7.06641 10.5449C7.3125 10.8184 7.72266 10.8184 7.99609 10.5723L12.8086 5.97853Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineFullArrowRight;
