import { useEffect, useState } from 'react';

/**
 * Breakpoint
 *
 * @typedef
 */
export type breakpoint = 'xs' | 'sm' | 'md';

/**
 * Get Breakpoint Get Breakpoint based on a give width
 *
 * @param {number} width - The width of the window.
 * @returns {breakpoint} The breakpoint based on the width.
 */
export const getBreakpoint = (width: number): breakpoint => {
  if (width < 739) {
    return 'xs';
  } else if (width < 1199) {
    return 'sm';
  } else {
    return 'md';
  }
};

/**
 * Use Window Size Custom Hook to get the window size and use it in the
 * application
 *
 * @returns {object} The window size and breakpoint.
 */
export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    /** Width of the Window */
    width: number | undefined;
    /** Height of the Window */
    height: number | undefined;
    /** Breakpoint names */
    breakpoint: 'xs' | 'sm' | 'md' | undefined;
  }>({
    width: undefined,
    height: undefined,
    breakpoint: undefined,
  });
  useEffect(() => {
    /** Handle Resize Handle the resize event and update the window size */
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        breakpoint: getBreakpoint(window.innerWidth),
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}
