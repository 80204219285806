import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationCollar: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-collar',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-collar"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 73.4666C38.7111 73.4666 36.2222 71.5999 35.4667 70.9333C34.2667 69.9111 32 67.9999 32 64.6222C32 63.111 32.5333 61.7333 33.4667 60.7111C34.3556 59.7777 35.5556 59.2444 36.8 59.2444C37.9556 59.2444 39.0667 59.6888 40 60.5777C40.9333 59.7333 42.0444 59.2444 43.2 59.2444C45.5556 59.2444 48 61.2444 48 64.6222C48 67.9555 45.7778 69.9111 44.5333 70.9333C44.5333 70.8888 41.5111 73.4666 40 73.4666ZM36.8 61.8666C36.3111 61.8666 35.7778 62.1333 35.4222 62.5333C35.0667 62.8888 34.6667 63.5555 34.6667 64.6222C34.6667 66.5777 35.9111 67.8222 37.2 68.8888C38.2667 69.7777 39.5111 70.5333 40.0444 70.7555C40.5778 70.5333 41.7778 69.7777 42.8444 68.8888C44.1333 67.7777 45.3778 66.5777 45.3778 64.6222C45.3778 62.7555 44.1333 61.9111 43.2444 61.9111C42.4444 61.9111 41.7778 62.5333 41.4667 62.9333C41.1111 63.3333 40.5778 63.5999 40.0444 63.5999C39.5111 63.5999 38.9778 63.3333 38.6222 62.9333C38.1778 62.3999 37.5556 61.8666 36.8 61.8666Z"
        fill={color}
      />
      <path
        d="M46.6667 44.1333V42.3111C46.6667 41.4667 45.9555 40.7555 45.1111 40.7555H34.8889C34.0444 40.7555 33.3333 41.4667 33.3333 42.3111V44.1333L31.8667 44C17.8222 42.5333 6.79999 37.3333 3.1111 30.5333C2.22222 28.8889 1.77777 27.2 1.77777 25.4667C1.77777 14.8444 18.5778 6.53333 40 6.53333C61.4222 6.53333 78.2222 14.8444 78.2222 25.4667C78.2222 27.2 77.7778 28.8889 76.8889 30.4889C73.2 37.3333 62.1778 42.4889 48.1333 43.9556L46.6667 44.1333ZM34.8889 38.0889H45.1111C47.0667 38.0889 48.6667 39.3778 49.1555 41.1555C61.5555 39.6 71.4222 34.9778 74.5333 29.2C75.2 27.9555 75.5555 26.7111 75.5555 25.4222C75.5555 16.6222 59.2889 9.15555 40 9.15555C20.7111 9.15555 4.44444 16.6667 4.44444 25.4667C4.44444 26.7111 4.79999 28 5.46666 29.2444C8.57777 35.0222 18.4444 39.6444 30.8444 41.2C31.3333 39.4222 32.9333 38.0889 34.8889 38.0889Z"
        fill={color}
      />
      <path
        d="M33.3333 52.9334L31.8667 52.7556C14.1333 50.9334 1.77777 43.3334 1.77777 34.2667C1.77777 32.5334 2.22222 30.8445 3.1111 29.2445C7.5111 21.0667 22.6667 15.3334 40 15.3334C57.3333 15.3334 72.4889 21.0667 76.8889 29.2445C77.7778 30.8889 78.2222 32.5778 78.2222 34.2667C78.2222 43.2889 65.8667 50.8889 48.1333 52.8L46.6667 52.9778V42.3112C46.6667 41.4667 45.9555 40.7556 45.1111 40.7556H34.8889C34.0444 40.7556 33.3333 41.4667 33.3333 42.3112V52.9334ZM34.8889 38.0889H45.1111C47.4222 38.0889 49.3333 40 49.3333 42.3112V49.9556C64.6222 48 75.5555 41.5112 75.5555 34.2667C75.5555 33.0223 75.2 31.7334 74.5333 30.4889C70.6667 23.2889 56.1333 18 40 18C23.8667 18 9.33333 23.2889 5.46666 30.5334C4.79999 31.7778 4.44444 33.0223 4.44444 34.3112C4.44444 41.5556 15.3778 48.0445 30.6667 50V42.3112C30.6667 40 32.5778 38.0889 34.8889 38.0889Z"
        fill={color}
      />
      <path
        d="M78.2222 25.4667H75.5555V34.2667H78.2222V25.4667Z"
        fill={color}
      />
      <path
        d="M4.44444 25.4667H1.77777V34.2667H4.44444V25.4667Z"
        fill={color}
      />
      <path
        d="M45.1111 56.7555H34.8889C32.5778 56.7555 30.6667 54.8444 30.6667 52.5333V42.3111C30.6667 40 32.5778 38.0889 34.8889 38.0889H45.1111C47.4222 38.0889 49.3333 40 49.3333 42.3111V52.5333C49.3333 54.8889 47.4222 56.7555 45.1111 56.7555ZM34.8889 40.7555C34.0444 40.7555 33.3333 41.4666 33.3333 42.3111V52.5333C33.3333 53.3778 34.0444 54.0889 34.8889 54.0889H45.1111C45.9555 54.0889 46.6667 53.3778 46.6667 52.5333V42.3111C46.6667 41.4666 45.9555 40.7555 45.1111 40.7555H34.8889Z"
        fill={color}
      />
      <path d="M37.2 55.4222H34.5333V60.8889H37.2V55.4222Z" fill={color} />
      <path
        d="M45.5111 55.4222H42.8444V60.8889H45.5111V55.4222Z"
        fill={color}
      />
      <path
        d="M22.6222 53.2C20.2667 53.2 19.4222 49.2444 19.4222 45.8667C19.4222 42.4889 20.2667 38.5333 22.6222 38.5333C23.3778 38.5333 23.9555 39.1111 23.9555 39.8667C23.9555 40.4889 23.5111 41.0222 22.9333 41.1555C22.5778 41.6 22.0889 43.2889 22.0889 45.8667C22.0889 48.4444 22.6222 50.0889 22.9333 50.5778C23.5111 50.7111 23.9555 51.2444 23.9555 51.8667C23.9555 52.6222 23.3333 53.2 22.6222 53.2Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationCollar;
