import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineHome: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-outline-home',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-home"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M21 11.9843C21 11.8591 20.9374 11.7339 20.8122 11.6086L12.3287 4.09393C12.2348 4.03131 12.1096 4 11.9843 4C11.8904 4 11.7652 4.03131 11.6713 4.09393L3.18783 11.6086C3.0313 11.7339 3 11.8591 3 11.9843C3 12.2661 3.21913 12.4853 3.50087 12.4853C3.59478 12.4853 3.72 12.454 3.81391 12.3601L5.00348 11.3268V17.4951C5.00348 18.9041 6.09913 20 7.50783 20H16.4922C17.8696 20 18.9652 18.9041 18.9652 17.5264V11.3581L20.1861 12.3601C20.28 12.454 20.4052 12.4853 20.4991 12.4853C20.7809 12.4853 21 12.2661 21 11.9843ZM17.9948 10.4814V17.4951C17.9948 18.3405 17.3061 18.998 16.5235 18.998H7.50783C6.66261 18.998 6.00522 18.3405 6.00522 17.4951V10.4814C6.00522 10.4814 6.00522 10.4814 6.00522 10.4501L12.0157 5.15851L18.0261 10.4501C18.0261 10.4814 17.9948 10.4814 17.9948 10.4814Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconOutlineHome;
