import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationTags: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-tags',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-tags"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0445 75.1555C15.4667 75.1555 5.20001 64.8889 5.20001 52.3111V14.6222C5.20001 10.5333 8.53334 7.15552 12.6667 7.15552H42.0889C46.2667 7.15552 49.6445 10.5333 49.6445 14.7111V53.5555C49.6445 65.4666 39.9556 75.1555 28.0445 75.1555ZM12.6667 9.82218C10.0445 9.82218 7.86668 11.9555 7.86668 14.6222V52.3111C7.86668 63.4222 16.9333 72.4889 28.0445 72.4889C38.4889 72.4889 46.9778 64 46.9778 53.5555V14.7111C46.9778 12 44.8 9.82218 42.0889 9.82218H12.6667Z"
        fill={color}
      />
      <path
        d="M52.7556 75.2445C49.8667 75.2445 46.9778 74.7111 44.2222 73.6C43.5556 73.3334 43.2 72.5334 43.4667 71.8667C43.7333 71.2 44.5333 70.8445 45.2 71.1111C50.0889 73.0667 55.4667 73.0667 60.3111 71.0667C69.9556 67.1111 74.6222 56.0889 70.7111 46.4L56.0889 10.4445C55.0667 7.95557 52.2222 6.75557 49.6889 7.73335C49.0222 8.00002 48.2222 7.68891 47.9556 7.02224C47.6889 6.35557 48 5.55557 48.6667 5.28891C52.5333 3.73335 56.9333 5.60002 58.5333 9.42224L73.1556 45.3778C77.6445 56.4 72.3111 69.0222 61.2889 73.5111C58.5778 74.7111 55.6445 75.2445 52.7556 75.2445Z"
        fill={color}
      />
      <path
        d="M27.4222 26.2667C24 26.2667 21.2 23.4667 21.2 20.0445C21.2 16.6223 24 13.8223 27.4222 13.8223C30.8445 13.8223 33.6445 16.6223 33.6445 20.0445C33.6445 23.4667 30.8445 26.2667 27.4222 26.2667ZM27.4222 16.4889C25.4667 16.4889 23.8667 18.0889 23.8667 20.0445C23.8667 22 25.4667 23.6 27.4222 23.6C29.3778 23.6 30.9778 22 30.9778 20.0445C30.9778 18.0889 29.3778 16.4889 27.4222 16.4889Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationTags;
