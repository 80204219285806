import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineFullArrowLeft: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-full-arrow-left"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.191406 5.97853C0.0546875 5.84181 0 5.67775 0 5.48634C0 5.32228 0.0546875 5.15822 0.191406 5.0215L5.00391 0.427747C5.27734 0.181654 5.6875 0.181654 5.93359 0.455091C6.17969 0.701185 6.17969 1.13868 5.90625 1.38478L2.26953 4.83009H11.5938C11.9492 4.83009 12.25 5.13087 12.25 5.48634C12.25 5.86915 11.9492 6.14259 11.5938 6.14259H2.26953L5.90625 9.61525C6.17969 9.86134 6.17969 10.2715 5.93359 10.5449C5.6875 10.8184 5.27734 10.8184 5.00391 10.5723L0.191406 5.97853Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineFullArrowLeft;
