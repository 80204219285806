import React from 'react';
import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconFillSortDown: React.FC<IIconType> = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 6"
      fill="none"
      data-testid="icon-fill-sort-down"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.13792 0C9.88792 0 10.2942 0.9375 9.73167 1.5L5.60667 5.75C5.45042 5.9375 5.23167 6 5.01292 6C4.76292 6 4.54417 5.9375 4.38792 5.75L0.262921 1.5C-0.299579 0.9375 0.106671 0 0.856671 0H9.13792Z"
        fill={color}
      />
    </svg>
  );
};

export default IconFillSortDown;
