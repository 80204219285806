import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineCat: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-cat"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 4C19.6875 4 19.4062 4.15625 19.2188 4.375L17.8438 6H17.125L15.75 4.375C15.4688 4.03125 14.9688 3.90625 14.5312 4.125C14.1875 4.28125 14 4.65625 14 5.0625V9H13.5C10.25 9 7.40625 10.8438 6 13.5312V9.5C6 8.125 4.875 7 3.5 7C3.21875 7 3 7.25 3 7.5C3 7.78125 3.21875 8 3.5 8C4.3125 8 5 8.6875 5 9.5V17.5C5 18.9062 6.09375 20 7.5 20H13.9688C14.5312 20 15 19.5312 15 18.9688C14.9688 17.9688 14.1875 17.1562 13.1875 17.0312L16 15.7812V18.5C16 19.3438 16.6562 20 17.5 20C18.3125 20 19 19.3438 19 18.5V12.1562C20.1562 11.5938 21 10.4062 21 9.03125V5C21 4.46875 20.5312 4 20 4ZM18 18.5C18 18.7812 17.75 19 17.5 19C17.2188 19 17 18.7812 17 18.5V15.0312C17 14.6875 16.625 14.4375 16.2812 14.5938L12.4688 16.2812C12.3438 15.1875 11.6875 14.2188 10.6875 13.75C10.4062 13.625 10.0625 13.7812 9.96875 14.0938C9.90625 14.3125 10.0312 14.5625 10.25 14.6562C10.9375 15 11.5 15.625 11.5 16.4062V18H13C13.5312 18 14 18.4688 14 19H7.5C6.6875 19 6 18.4062 6 17.5938C5.9375 13.4375 9.3125 10 13.5 10H14.1562C14.5938 11.4688 15.9062 12.5 17.5 12.5C17.6562 12.5 17.8125 12.5 18 12.4688V18.5ZM20 9C20 10.4062 18.875 11.5 17.5 11.5C16.0938 11.5 15 10.4062 15 9V5L16.6562 7H18.3125L20 5V9ZM16.5 8C16.2188 8 16 8.25 16 8.5C16 8.78125 16.2188 9 16.5 9C16.75 9 17 8.78125 17 8.5C17 8.25 16.75 8 16.5 8ZM18 8.5C18 8.78125 18.2188 9 18.5 9C18.75 9 19 8.78125 19 8.5C19 8.25 18.75 8 18.5 8C18.2188 8 18 8.25 18 8.5Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineCat;
