import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color}
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineEyeSlash: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-eye-slash"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5173 8.30228L11.486 9.05294C11.6735 9.02166 11.8298 8.99038 12.0173 8.99038C13.5798 8.99038 14.8923 10.2415 14.986 11.8053L15.9548 12.5873C15.986 12.3996 16.0173 12.2119 16.0173 12.0243C16.0173 9.80359 14.2048 7.98951 12.0173 7.98951C11.486 7.98951 10.986 8.11462 10.5173 8.30228ZM12.0173 17.9983C8.67352 17.9983 5.61102 15.7776 4.04852 12.1494C4.01727 12.1181 4.01727 12.0243 3.98602 12.0243C3.98602 11.993 3.98602 11.8992 3.98602 11.8679C4.39227 10.9609 4.89227 10.1476 5.45477 9.42826L4.67352 8.80272C4.04852 9.58465 3.51727 10.4917 3.07977 11.4613C3.04852 11.6177 2.98602 11.8366 2.98602 11.993C2.98602 12.1494 3.01727 12.3996 3.07977 12.556C4.76727 16.4031 8.14227 18.9991 11.986 18.9991C13.486 18.9991 14.8923 18.5925 16.1735 17.8731L15.3298 17.185C14.2985 17.7168 13.1735 17.9983 12.0173 17.9983ZM12.0173 5.98776C15.3298 5.98776 18.3923 8.23973 19.986 11.8679C19.986 11.8992 19.986 11.993 19.986 11.993C19.986 12.0243 19.986 12.1181 19.986 12.1494C19.5798 13.0564 19.0798 13.8696 18.5173 14.589L19.2985 15.2146C19.9235 14.4326 20.4548 13.5256 20.8923 12.5247C20.9548 12.3996 20.986 12.1494 20.986 11.993C20.986 11.8366 20.9548 11.6177 20.8923 11.4613C19.2048 7.61418 15.8298 5.01816 12.0173 5.01816C10.486 5.01816 9.07977 5.42477 7.79852 6.14415L8.64227 6.83225C9.70477 6.30053 10.8298 5.98776 12.0173 5.98776ZM12.0173 15.9965C12.5173 15.9965 13.0173 15.9027 13.486 15.715L12.5173 14.9644C12.3298 14.9956 12.1735 15.0269 12.0173 15.0269C10.4235 15.0269 9.11102 13.7758 9.01727 12.2119L8.04852 11.43C8.01727 11.6177 8.01727 11.8053 8.01727 11.993C8.01727 14.2137 9.79852 15.9965 12.0173 15.9965ZM21.7985 19.1242L2.79852 4.11112C2.57977 3.92346 2.26727 3.98601 2.11102 4.20495C1.92352 4.39262 1.98602 4.73667 2.20477 4.89305L21.2048 19.9062C21.2985 19.9687 21.3923 20 21.5173 20C21.6423 20 21.7985 19.9374 21.8923 19.8123C22.0798 19.5934 22.0173 19.2806 21.7985 19.1242Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineEyeSlash;
