import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationPetFood: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-pet-food',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-pet-food"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.3333 71.8667L2.66666 71.7778C2.26666 71.7778 1.91111 71.6 1.68888 71.3333C1.42222 71.0667 1.33333 70.6667 1.33333 70.3111C3.95555 45.5111 6.26666 41.6444 6.93333 41.0667C7.2 40.8444 7.51111 40.7111 7.82222 40.7111H20.1778C20.9333 40.7111 21.5111 41.2889 21.5111 42.0444C21.5111 42.8 20.9333 43.3778 20.1778 43.3778H8.62222C7.99999 44.8444 6.17777 50.4444 4.13333 69.1556L75.8222 69.2444C73.3778 47.0222 71.3333 43.3333 71.1111 43.0222C70.6667 42.5333 70.6667 41.7333 71.1556 41.2444C71.6444 40.7111 72.4889 40.6667 73.0222 41.1555C73.6889 41.7333 76 45.6444 78.6222 70.4C78.6667 70.7555 78.5333 71.1556 78.2667 71.4222C78.0889 71.6889 77.6889 71.8667 77.3333 71.8667Z"
        fill={color}
      />
      <path
        d="M71.4667 43.3778H32.9778C32.2222 43.3778 31.6444 42.8 31.6444 42.0444C31.6444 41.2889 32.2222 40.7111 32.9778 40.7111H71.4667C72.2222 40.7111 72.8 41.2889 72.8 42.0444C72.8 42.8 72.2222 43.3778 71.4667 43.3778Z"
        fill={color}
      />
      <path
        d="M42.8889 65.9111C42.6222 65.9111 42.4 65.8667 42.1333 65.8222C41.2 65.6445 40.4889 65.1111 40 64.6667C39.5111 65.1111 38.7555 65.6445 37.8667 65.8222C36.8 66.0445 35.6889 65.7778 34.6222 65.0667C33.8222 64.5333 33.3333 63.7778 33.1555 62.8889C32.8 61.0222 33.9555 59.0667 34.4889 58.3111C35.8667 56.3111 38.4889 54.0444 40 54.0444C41.6 54.0444 44.0889 56.2222 45.5111 58.3111C46.0444 59.0667 47.2 61.0222 46.8444 62.8889C46.6667 63.7778 46.1778 64.5333 45.3778 65.0667C44.5778 65.6 43.7333 65.9111 42.8889 65.9111ZM40 62.4444C40.3111 62.4444 40.5778 62.5778 40.7555 62.8444C41.0222 63.2 42.3555 64.9778 44.4 63.5556C44.8 63.2889 45.0222 62.9333 45.1111 62.4889C45.2889 61.6445 44.8889 60.4 44.0444 59.2445C42.5778 57.0667 40.5778 55.7778 40 55.7778C39.5111 55.8222 37.3778 57.2 35.9555 59.2445C35.1555 60.4 34.7555 61.6445 34.8889 62.4889C34.9778 62.9333 35.2 63.2889 35.6 63.5556C37.6444 64.9333 38.9778 63.2 39.2444 62.8C39.4222 62.6222 39.6889 62.4444 40 62.4444Z"
        fill={color}
      />
      <path
        d="M46.8 58.3556C46.4444 58.3556 46.0889 58.2667 45.7778 58.0889C45.0222 57.6889 44.7555 57.1111 44.6222 56.7111C44.4 56 44.5778 55.1556 45.1111 54.1778C45.5555 53.3778 46.3111 52.8 47.1111 52.5778C47.6889 52.4 48.3111 52.4889 48.8 52.8C49.8222 53.4222 50.5333 54.7556 49.4222 56.6667C48.7555 57.7333 47.7778 58.3556 46.8 58.3556ZM47.6889 54.3111C47.6444 54.3111 47.5555 54.3111 47.5111 54.3556C47.2444 54.4444 46.8889 54.6667 46.6222 55.1111C46.3555 55.6 46.2222 56 46.2667 56.2667C46.2667 56.3111 46.3111 56.4444 46.5333 56.5778C46.8444 56.7556 47.4222 56.4889 47.7778 55.8222C48.4 54.7111 47.9555 54.4889 47.8222 54.3556C47.8222 54.3111 47.7778 54.3111 47.6889 54.3111Z"
        fill={color}
      />
      <path
        d="M33.2 58.3556C32.2222 58.3556 31.2 57.7333 30.5778 56.6667C29.4667 54.7556 30.1778 53.3778 31.2 52.8C31.6889 52.4889 32.3111 52.4444 32.8889 52.5778C33.6889 52.8 34.4 53.3778 34.8889 54.1778C35.4222 55.1111 35.6 55.9556 35.3778 56.7111C35.2444 57.1111 34.9778 57.6889 34.2222 58.0889C33.9111 58.2667 33.5555 58.3556 33.2 58.3556ZM32.1333 55.8222C32.4889 56.4444 33.1111 56.7111 33.3778 56.5778C33.6 56.4444 33.6444 56.3111 33.6444 56.2667C33.6889 56.0444 33.6 55.6 33.2889 55.1111C33.0222 54.6667 32.6667 54.4 32.4 54.3556C32.3111 54.3111 32.1778 54.3111 32.0444 54.3556C31.9555 54.4444 31.5111 54.7111 32.1333 55.8222Z"
        fill={color}
      />
      <path
        d="M42.4 53.6C42.1778 53.6 42 53.5556 41.7778 53.5111C41.0222 53.3333 40.4444 52.9333 40.1333 52.3111C39.6889 51.5556 39.6444 50.5333 40.0444 49.2889C40.5778 47.5556 42.1778 46.4444 43.7333 46.8C44.4 46.9333 44.9778 47.3333 45.3333 47.9556C45.8222 48.7556 45.8666 49.8667 45.5555 51.0222C45.0222 52.5778 43.7778 53.6 42.4 53.6ZM43.1555 48.5333C42.5778 48.5333 42 49.0667 41.7333 49.8222C41.5111 50.5333 41.5111 51.1111 41.6889 51.4222C41.7333 51.5111 41.8666 51.6889 42.2222 51.7333C42.7555 51.8667 43.5111 51.4222 43.8222 50.4444C44 49.8222 44 49.2 43.7778 48.8444C43.6889 48.6667 43.5555 48.5778 43.3778 48.5333C43.2889 48.5778 43.2 48.5333 43.1555 48.5333Z"
        fill={color}
      />
      <path
        d="M36.8444 53.9111C35.5111 53.9111 34.2222 52.8889 33.7778 51.3333C33.4222 50.2222 33.5111 49.1111 34 48.2667C34.3556 47.6889 34.8889 47.2889 35.6 47.1111C37.1556 46.8 38.7556 47.8667 39.2889 49.6C39.6444 50.8 39.6444 51.8222 39.2 52.6222C38.8444 53.2 38.2667 53.6445 37.5556 53.7778C37.2444 53.9111 37.0222 53.9111 36.8444 53.9111ZM35.4667 50.8445C35.7778 51.8222 36.5333 52.2667 37.0667 52.1333C37.4222 52.0445 37.5556 51.9111 37.6 51.8222C37.7778 51.5111 37.7778 50.9333 37.5556 50.2222C37.2889 49.3333 36.5778 48.8 35.9556 48.9333C35.7778 48.9778 35.6444 49.0667 35.5556 49.2445C35.2889 49.5556 35.2444 50.2222 35.4667 50.8445Z"
        fill={color}
      />
      <path
        d="M32.9778 43.3778H20.2222C19.4667 43.3778 18.8889 42.8 18.8889 42.0444C18.8889 41.2889 19.4667 40.7111 20.2222 40.7111H32.9778C33.7333 40.7111 34.3111 41.2889 34.3111 42.0444C34.3111 42.8 33.6889 43.3778 32.9778 43.3778Z"
        fill={color}
      />
      <path
        d="M23.7778 43.3778C23.3778 43.3778 22.9778 43.2 22.7111 42.8889L15.6 33.8667C14.2222 34.8 12.5778 35.2444 10.8889 35.0222C9.06666 34.8 7.42221 33.8667 6.3111 32.4444C5.15554 31.0222 4.66666 29.2 4.88888 27.3778C5.1111 25.5555 6.04443 23.9111 7.46666 22.8C8.88888 21.6444 10.7111 21.1555 12.5333 21.3778C13.3778 21.4667 14.1778 21.7333 14.8889 22.0889C14.3555 19.5555 15.2444 16.8889 17.3778 15.2444C18.8444 14.0889 20.6222 13.6 22.4444 13.8222C24.2667 14.0444 25.9111 14.9778 27.0222 16.4C28.1778 17.8667 28.6667 19.6444 28.4444 21.4667C28.2667 23.1111 27.4667 24.6222 26.2222 25.7333L30.8 31.2C31.2889 31.7778 31.2 32.6222 30.6222 33.0667C30.0444 33.5555 29.2 33.4667 28.7555 32.8889L23.2889 26.3555C23.0222 26.0444 22.9333 25.6444 23.0222 25.2444C23.1111 24.8444 23.3333 24.5333 23.6889 24.3111C23.9111 24.1778 24.0889 24.0889 24.2667 23.9555C25.1555 23.2444 25.7333 22.2667 25.8667 21.1555C26 20.0444 25.6889 18.9333 24.9778 18.0444C24.2667 17.1555 23.2889 16.5778 22.1778 16.4444C21.0667 16.3111 19.9555 16.6222 19.0667 17.3333C17.2889 18.7555 16.9333 21.2889 18.2667 23.1111C18.6667 23.6889 18.5778 24.4889 18 24.9333L16.8889 25.7778C16.3111 26.2222 15.4667 26.1333 15.0222 25.5555C14.3111 24.6667 13.3333 24.0889 12.2222 23.9555C11.1111 23.8222 9.99999 24.1333 9.1111 24.8444C8.22221 25.5555 7.64443 26.5333 7.5111 27.6444C7.37777 28.7555 7.68888 29.8667 8.39999 30.7555C9.1111 31.6444 10.0889 32.2222 11.2 32.3555C12.3111 32.4889 13.4222 32.1778 14.3111 31.4667C14.4889 31.3333 14.6222 31.2 14.8 31.0222C15.0667 30.7555 15.4222 30.5778 15.8222 30.5778C16.2222 30.5778 16.5778 30.8 16.8 31.0667L24.7555 41.1555C25.2 41.7333 25.1111 42.5778 24.5333 43.0222C24.3555 43.2444 24.0444 43.3778 23.7778 43.3778Z"
        fill={color}
      />
      <path
        d="M66.5778 42.8889C66.0889 42.8889 65.6444 42.6222 65.4222 42.2222C65.0667 41.6 65.2889 40.7555 65.9555 40.4C67.6889 39.4667 68.7111 37.8667 68.7111 36.0889C68.7111 33.2889 66.0444 30.9778 62.7111 30.9778C62.6222 30.9778 62.5778 30.9778 62.5333 30.9778C62 30.9778 61.5555 30.7111 61.3333 30.2667C59.7778 27.2 56.2667 25.2 52.4 25.2C47.9111 25.2 44.2222 29.9555 43.3778 34.3555C43.2889 34.8 42.9778 35.2 42.5333 35.3333C42.0889 35.5111 41.6 35.4222 41.2444 35.1111C40.8889 34.8444 40.4889 34.4889 40.0889 34.1333C38.8444 33.0667 36.9778 31.4222 35.7778 31.4222C31.7778 31.4222 28.5333 34.2222 28.5333 37.6444C28.5333 38.7555 28.8889 39.8222 29.5111 40.7555C29.9111 41.3778 29.7778 42.1778 29.1555 42.6222C28.5333 43.0222 27.7333 42.8889 27.2889 42.2667C26.2667 40.8889 25.7778 39.2889 25.7778 37.6444C25.7778 32.7555 30.2222 28.7555 35.6889 28.7555C37.6889 28.7555 39.6444 30.3111 41.2889 31.6889C42.9778 26.8889 47.1556 22.5333 52.3556 22.5333C56.9333 22.5333 61.1111 24.8 63.2889 28.3111C67.7778 28.5778 71.3778 31.9555 71.3778 36.0889C71.3778 38.8 69.8222 41.2889 67.2889 42.7111C67.0222 42.8444 66.8 42.8889 66.5778 42.8889Z"
        fill={color}
      />
      <path
        d="M11.8222 42.7556C11.7778 42.7556 11.6889 42.7556 11.6444 42.7556C10.9333 42.6667 10.4 42 10.4889 41.2444C10.9778 37.5556 14.3111 34.8 18.2222 34.8C18.9778 34.8 19.5555 35.3778 19.5555 36.1333C19.5555 36.8889 18.9778 37.4667 18.2222 37.4667C15.6444 37.4667 13.4667 39.2444 13.1555 41.6C13.0667 42.2667 12.4889 42.7556 11.8222 42.7556Z"
        fill={color}
      />
      <path
        d="M28 30.7111C27.7778 30.7111 27.6 30.6667 27.3778 30.5778C26.7111 30.2222 26.4444 29.4222 26.8 28.8C28.8444 24.8 32.7111 22.3556 36.9778 22.3556C40.4889 22.3556 43.7333 24 45.9555 26.9333C46.4 27.5111 46.3111 28.3556 45.6889 28.8C45.1111 29.2445 44.2667 29.1111 43.8222 28.5333C42.1333 26.3111 39.6 25.0222 36.9778 25.0222C33.7333 25.0222 30.7555 26.9333 29.2 30C28.9333 30.4444 28.4444 30.7111 28 30.7111Z"
        fill={color}
      />
      <path
        d="M42.6667 20.1333C43.1576 20.1333 43.5555 19.7354 43.5555 19.2444C43.5555 18.7535 43.1576 18.3555 42.6667 18.3555C42.1757 18.3555 41.7778 18.7535 41.7778 19.2444C41.7778 19.7354 42.1757 20.1333 42.6667 20.1333Z"
        fill={color}
      />
      <path
        d="M42.6667 21.0222C41.6889 21.0222 40.8889 20.2222 40.8889 19.2444C40.8889 18.2667 41.6889 17.4667 42.6667 17.4667C43.6444 17.4667 44.4444 18.2667 44.4444 19.2444C44.4444 20.2222 43.6444 21.0222 42.6667 21.0222Z"
        fill={color}
      />
      <path
        d="M8.66667 38.8C8.04445 38.8 7.55556 38.3111 7.55556 37.6889C7.55556 37.0667 8.04445 36.5778 8.66667 36.5778C9.28889 36.5778 9.77778 37.0667 9.77778 37.6889C9.77778 38.3111 9.28889 38.8 8.66667 38.8ZM8.66667 37.4667C8.53334 37.4667 8.44445 37.5556 8.44445 37.6889C8.44445 37.8222 8.53334 37.9111 8.66667 37.9111C8.8 37.9111 8.88889 37.8222 8.88889 37.6889C8.88889 37.5556 8.8 37.4667 8.66667 37.4667Z"
        fill={color}
      />
      <path
        d="M61.5556 17.4667C60.9333 17.4667 60.4444 16.9778 60.4444 16.3556C60.4444 15.7333 60.9333 15.2444 61.5556 15.2444C62.1778 15.2444 62.6667 15.7333 62.6667 16.3556C62.6667 16.9778 62.1778 17.4667 61.5556 17.4667ZM61.5556 16.1333C61.4222 16.1333 61.3333 16.2222 61.3333 16.3556C61.3333 16.4889 61.4222 16.5778 61.5556 16.5778C61.6889 16.5778 61.7778 16.4889 61.7778 16.3556C61.7778 16.2222 61.6889 16.1333 61.5556 16.1333Z"
        fill={color}
      />
      <path
        d="M64.4444 23.0222C63.3333 23.0222 62.4444 22.1333 62.4444 21.0222C62.4444 19.9111 63.3333 19.0222 64.4444 19.0222C65.5556 19.0222 66.4444 19.9111 66.4444 21.0222C66.4444 22.1333 65.5556 23.0222 64.4444 23.0222ZM64.4444 20.3556C64.0889 20.3556 63.7778 20.6667 63.7778 21.0222C63.7778 21.3778 64.0889 21.6889 64.4444 21.6889C64.8 21.6889 65.1111 21.3778 65.1111 21.0222C65.1111 20.6667 64.8 20.3556 64.4444 20.3556Z"
        fill={color}
      />
      <path
        d="M51.5111 20.2667C51.1556 20.2667 50.8444 19.9556 50.8444 19.6C50.8444 18.5778 50.5333 16.7556 49.8667 16.1778C48.8889 15.3778 47.7778 15.2889 47.7778 15.2889H46.5333C46.1778 15.2889 45.8667 14.9778 45.8667 14.6222C45.8667 14.2667 46.1778 13.9556 46.5333 13.9556C47.1556 13.9556 49.0222 13.7778 49.7333 13.0667C50.5778 12.1778 50.8444 11.3778 50.8889 9.42222C50.8889 9.06667 51.2 8.75555 51.5556 8.75555C51.9111 8.75555 52.2222 9.06667 52.2222 9.42222C52.2222 10.1333 52.4444 12.1333 53.2889 13.0222C53.8667 13.6 54.7111 13.6889 55.2 13.7333C55.2889 13.7333 55.3333 13.7333 55.3778 13.7333C55.9556 13.8222 56.6667 13.8667 56.6667 13.8667C57.0222 13.9111 57.2889 14.2222 57.2444 14.5778C57.2444 14.9333 56.9333 15.2 56.5778 15.2C55.8222 15.2 54.4444 15.3333 53.9111 15.6444C53.1556 16.0889 52.7111 16.6222 52.4444 17.3778C52.2222 18 52.1778 19.1556 52.1778 19.5556C52.1778 20 51.8667 20.2667 51.5111 20.2667ZM49.8667 14.6222C50.1333 14.7556 50.4 14.9333 50.7111 15.1556C51.0667 15.4222 51.3333 15.8667 51.5556 16.3111C51.9111 15.6444 52.4444 15.1111 53.2 14.6222C52.8889 14.4444 52.5778 14.2667 52.3111 13.9556C52 13.6444 51.7778 13.2444 51.6 12.8444C51.3778 13.2444 51.1111 13.6444 50.7556 14.0444C50.4444 14.2667 50.1778 14.4444 49.8667 14.6222Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationPetFood;
