import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color}
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineDog: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-dog"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 6.5H17.5L17.25 6.0625C17.125 5.75 16.7188 5.5 16.375 5.5H13.5L13.4688 5.03125C13.4688 4.75 13.25 4.5 13 4.5C12.7188 4.5 12.5 4.75 12.5 5V10.5H9C8.40625 10.5 7.8125 10.6875 7.34375 11H6.5C5.65625 11 5 10.3438 5 9.5C5 9.25 4.75 9 4.5 9C4.21875 9 4 9.25 4 9.5C4 10.875 5.0625 11.9688 6.40625 12C6.125 12.4688 6 12.9688 6 13.5V18C6 18.8438 6.65625 19.5 7.5 19.5H8.5C9.3125 19.5 10 18.8438 10 18V15.5H13V18C13 18.8438 13.6562 19.5 14.5 19.5H15.5C16.3125 19.5 17 18.8438 17 18V11L18 11.0312C19.0938 11.0312 20 10.125 20 9.03125V7.5C20 6.96875 19.5312 6.5 19 6.5ZM16 18C16 18.2812 15.75 18.5 15.5 18.5H14.5C14.2188 18.5 14 18.2812 14 18V15C14 14.75 13.75 14.5 13.5 14.5H9.5C9.21875 14.5 9 14.75 9 15V18C9 18.2812 8.75 18.5 8.5 18.5H7.5C7.21875 18.5 7 18.2812 7 18V13.5C7 12.4062 7.875 11.5 9 11.5H12.9062L16 12.5312V18ZM19 9C19 9.5625 18.5312 10 18 10H16V11.5L13.5 10.6562V6.5H16.375L16.875 7.5H19V9ZM15.5 7C15.2188 7 15 7.25 15 7.5C15 7.78125 15.2188 8 15.5 8C15.75 8 16 7.78125 16 7.5C16 7.25 15.75 7 15.5 7Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineDog;
