import { notApplicableString } from '@/lib/constants/constants/analytics';
import { analyticsFeatureKey } from '@/lib/utils/featureFlags/optimizely/middleware';
import CookieStorage from '../utils/storage/cookie-storage';

export const GA_TRACKING_ID: string = process.env.NEXT_PUBLIC_GTM_ID as string;

/**
 * This will track the page to be sent as page view tag
 *
 * @param {string} url The url to be tracked
 */
export const pageView = (url: string) => {
  const cookies = new CookieStorage();
  // Get the analytics feature from the cookies
  const optimizelyDecision = cookies.get(analyticsFeatureKey) as {
    /** The analytic feature that is enabled */
    analytic: string;
  };
  // Default the feature to not applicable
  let feature: string = notApplicableString;
  if (optimizelyDecision && optimizelyDecision.analytic) {
    // Split the string into an array
    feature = optimizelyDecision.analytic;
  }

  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
    feature,
  });
};
