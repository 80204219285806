import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineMenu: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-outline-menu',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-menu"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id}`}>
        <path
          d="M20.05 11H3.95C3.42533 11 3 11.4253 3 11.95V12.05C3 12.5747 3.42533 13 3.95 13H20.05C20.5747 13 21 12.5747 21 12.05V11.95C21 11.4253 20.5747 11 20.05 11Z"
          fill={color}
        />
        <path
          d="M20.05 18H3.95C3.42533 18 3 18.4253 3 18.95V19.05C3 19.5747 3.42533 20 3.95 20H20.05C20.5747 20 21 19.5747 21 19.05V18.95C21 18.4253 20.5747 18 20.05 18Z"
          fill={color}
        />
        <path
          d="M20.05 4H3.95C3.42533 4 3 4.42533 3 4.95V5.05C3 5.57467 3.42533 6 3.95 6H20.05C20.5747 6 21 5.57467 21 5.05V4.95C21 4.42533 20.5747 4 20.05 4Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconOutlineMenu;
