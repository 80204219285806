import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationLightbulb: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-lightbulb',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-lightbulb"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 23.2889C38.2222 23.2889 34.5778 26.1778 32.4444 29.2445C31.7333 30.2667 30.1333 32.9778 30.5778 35.4667C30.8 36.6222 31.4222 37.5556 32.4 38.2222C33.8222 39.1556 35.2 39.5111 36.6222 39.2444C38.2222 38.9333 39.3778 37.8667 39.9555 37.2C40.5333 37.8667 41.6889 38.9333 43.2889 39.2444C43.6 39.2889 43.9111 39.3333 44.2222 39.3333C45.3333 39.3333 46.4 38.9778 47.5111 38.2222C48.5333 37.5556 49.1555 36.6222 49.3333 35.4667C49.8222 32.9333 48.1778 30.2667 47.4667 29.2445C45.2889 25.9556 41.8667 23.2889 40 23.2889Z"
        fill={color}
      />
      <path
        d="M52.6222 21.3778C52.0444 21.0667 51.3778 20.9778 50.7111 21.1556C49.7333 21.4222 48.8444 22.1333 48.2222 23.1556C47.5111 24.3556 47.2889 25.4222 47.5555 26.3111C47.7333 26.9778 48.1778 27.5111 48.8889 27.8667C49.2444 28.0445 49.6444 28.1778 50.0889 28.1778C51.2889 28.1778 52.5333 27.4222 53.2889 26.0889C54.4444 24 54.2222 22.3111 52.6222 21.3778Z"
        fill={color}
      />
      <path
        d="M32.4444 26.3111C32.7111 25.4222 32.4444 24.4 31.7778 23.1556C31.2 22.1333 30.2667 21.3778 29.2889 21.1556C28.5778 20.9778 27.9111 21.0667 27.3778 21.3778C25.7778 22.3111 25.5555 24.0445 26.7555 26.0889C27.5111 27.4222 28.7555 28.1778 29.9555 28.1778C30.3555 28.1778 30.7555 28.0889 31.1555 27.8667C31.8222 27.5111 32.2667 26.9778 32.4444 26.3111Z"
        fill={color}
      />
      <path
        d="M42.8444 20.9778C43.0667 21.0222 43.3333 21.0667 43.5556 21.0667C45.2 21.0667 46.8 19.7778 47.4222 17.7778C47.8667 16.3111 47.7778 14.8889 47.2 13.8667C46.8 13.1556 46.1333 12.7111 45.3333 12.5333C43.4222 12.1333 41.4667 13.5111 40.7556 15.7333C40.2667 17.2889 40.3111 18.6222 40.8444 19.6C41.2889 20.3111 41.9556 20.8 42.8444 20.9778Z"
        fill={color}
      />
      <path
        d="M35.2889 21.5556C35.5111 21.5556 35.7778 21.5111 36 21.4667C36.8889 21.2445 37.5556 20.8 38 20.0889C38.5333 19.1556 38.5778 17.8222 38.0889 16.2222C37.4222 14 35.4222 12.6222 33.5111 13.0222C32.7111 13.2 32.0889 13.6445 31.6444 14.3556C31.0667 15.3333 30.9778 16.8 31.4222 18.2667C32.0444 20.2667 33.6444 21.5556 35.2889 21.5556Z"
        fill={color}
      />
      <path
        d="M40 62.8444C39.9111 62.8444 30.2222 62.7556 29.8222 59.2C29.6444 57.6889 29.6 56.4445 29.5111 55.3333C29.3778 53.0667 29.3333 51.6889 28.3555 49.8667C27.9111 49.0222 26.7555 47.8222 25.4222 46.4445C21.9555 42.8445 16.7555 37.4222 15.8222 30C14.7555 21.7778 16.5333 14.7111 20.9778 9.6889C25.2889 4.80001 31.6889 2.22223 39.5111 2.22223C47.4222 2.22223 54.2667 5.11112 58.8889 10.3111C63.4222 15.4222 65.2889 22.2667 64.3111 30.0445C63.3778 37.4667 58.1333 42.8889 54.7111 46.4889C53.3778 47.8667 52.2222 49.0667 51.7778 49.9111C50.8 51.7333 50.7111 53.1111 50.6222 55.3778C50.5778 56.4445 50.4889 57.6889 50.3111 59.2445C49.8222 62.7556 40.0889 62.8444 40 62.8444ZM32.4444 58.7111C33.2444 59.3778 37.2444 60.1778 40 60.1778C42.7555 60.1778 46.8 59.3778 47.6 58.7111C47.7333 57.3333 47.8222 56.2222 47.8667 55.2C48 52.8445 48.0889 50.9778 49.3333 48.6222C49.9555 47.4667 51.1555 46.2222 52.7111 44.6222C56.1333 41.1111 60.7555 36.2667 61.6 29.6889C62.4889 22.7111 60.8444 16.5778 56.8444 12.0445C52.7555 7.42223 46.6222 4.8889 39.4667 4.8889C32.4444 4.8889 26.7111 7.15556 22.9333 11.4222C19.0222 15.8667 17.4667 22.1778 18.4 29.6889C19.2444 36.2667 23.9111 41.0667 27.2889 44.6222C28.8444 46.2222 30.0444 47.4667 30.6667 48.6222C31.9111 50.9778 32.0444 52.8445 32.1333 55.2C32.2222 56.2222 32.3111 57.3778 32.4444 58.7111Z"
        fill={color}
      />
      <path
        d="M39.9555 68.1778C36.4444 68.1778 32.0444 66.8889 30.4444 66C29.8222 65.6445 29.5555 64.8444 29.9555 64.1778C30.3111 63.5556 31.1111 63.2889 31.7778 63.6889C33.0667 64.4 37.0222 65.5111 39.9555 65.5111C42.7111 65.5111 46.4889 64.7111 48.7555 63.6444C49.4222 63.3333 50.2222 63.6 50.5333 64.2667C50.8444 64.9333 50.5778 65.7333 49.9111 66.0445C46.9778 67.4667 42.7111 68.1778 39.9555 68.1778Z"
        fill={color}
      />
      <path
        d="M40.1778 72.8C36.6667 72.8 32.2667 71.5111 30.6667 70.6222C30.0444 70.2667 29.7778 69.4667 30.1778 68.8C30.5333 68.1778 31.3333 67.9111 32 68.3111C33.2889 69.0222 37.2444 70.1333 40.1778 70.1333C42.9333 70.1333 46.7111 69.3333 48.9778 68.2667C49.6444 67.9556 50.4444 68.2222 50.7555 68.8889C51.0667 69.5556 50.8 70.3556 50.1333 70.6667C47.2 72.0889 42.8889 72.8 40.1778 72.8Z"
        fill={color}
      />
      <path
        d="M40.4889 77.7778C37.8222 77.7778 35.1555 77.2889 33.4667 76.2667C32.8444 75.9111 32.6222 75.0667 32.9778 74.4445C33.3333 73.8222 34.1778 73.6 34.8 73.9556C37.3333 75.4667 43.3333 75.4667 46.0889 74C46.7555 73.6445 47.5555 73.9111 47.9111 74.5333C48.2667 75.1556 48 76 47.3778 76.3556C45.6 77.2889 43.0667 77.7778 40.4889 77.7778Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationLightbulb;
