import IIconType from '../IIconType';

/**
 * @param {IIconType} props - Icon props
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconFillSwitch: React.FC<IIconType> = ({ size }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#F5EFFB" />
      <path
        d="M46.5692 32.226C47.2321 32.2981 47.6741 32.8751 47.6004 33.5241C46.3482 40.8078 39.9397 46.0001 32.5 46.0001C28.154 46.0001 24.1763 44.1972 21.3036 41.2405L17.9888 44.5578C17.7679 44.7741 17.4732 44.8462 17.1786 44.8462C16.9576 44.8462 16.8103 44.8462 16.6629 44.7741C16.221 44.6299 16 44.1972 16 43.6924V34.4616C16 33.8847 16.5156 33.3078 17.1786 33.3078H26.6071C27.0491 33.3078 27.4911 33.5962 27.6384 34.0289C27.8594 34.4616 27.7121 34.9664 27.3438 35.327L22.9241 39.6539C25.3549 42.178 28.7433 43.6924 32.4263 43.6924C38.8348 43.6924 44.2121 39.2933 45.1696 33.1635C45.317 32.5145 45.9063 32.0818 46.5692 32.226ZM18.3571 40.952L23.7344 35.6155H18.3571V40.952Z"
        fill="#DFCCF3"
      />
      <path
        d="M46.9455 17.2981C47.3875 17.4423 47.6821 17.875 47.6821 18.3077V27.5385C47.6821 28.1875 47.0928 28.6923 46.5035 28.6923H37.075C36.5593 28.6923 36.1174 28.476 35.9701 28.0433C35.7491 27.6106 35.8964 27.1058 36.191 26.7452L40.6107 22.4183C38.2535 19.8942 34.7915 18.3077 31.1821 18.3077C24.7736 18.3077 19.3964 22.7788 18.3651 28.9087C18.2178 29.5577 17.6285 29.9904 17.0393 29.8462C16.3763 29.774 15.9343 29.1971 16.008 28.5481C17.2602 21.2644 23.6687 16 31.1821 16C35.4544 16 39.4321 17.875 42.3049 20.8317L45.6196 17.5144C45.8406 17.2981 46.1352 17.1538 46.5035 17.1538C46.6509 17.1538 46.7982 17.226 46.9455 17.2981ZM45.325 26.3846V21.1202L39.8741 26.3846H45.325Z"
        fill="#8E62BE"
      />
    </svg>
  );
};

export default IconFillSwitch;
