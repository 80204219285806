import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationLostThin: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-lost-thin',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-lost"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.9111 56.0444C49.3333 55.3778 50.2667 55.0667 52.2222 53.2444C54.1778 51.4222 54.7556 49.6889 55.6 50.3111C56.4889 51.0222 57.4222 51.8667 58.4 52.7555C63.7778 57.4222 69.1111 62.1333 74.5333 66.8C76.5778 68.5778 77.3778 70.7111 76.6222 73.2889C75.2889 77.6889 69.8222 78.8 66.6667 75.2444C61.6889 69.6889 56.8445 64.0444 51.9556 58.4C51.2 57.5555 50.8 57.0222 49.9111 56.0444Z"
        fill={color}
      />
      <path
        d="M37.2445 32.2222C34.9778 29.3333 34.8889 27.4222 30.3111 27.4222C25.7333 27.4222 26.4 29.8222 24.0889 32.0889C21.7778 34.3555 20.5333 34.6667 20.5333 37.0222C20.5333 40 22.2667 42.4444 25.6445 42.4444C27.1556 42.4444 28.2222 41.6444 30.6222 41.6444C33.0222 41.6444 34.0889 42.4444 35.6 42.4444C38.9778 42.4444 40.7111 40 40.7111 37.0222C40.7556 34.5778 38.7556 34.1333 37.2445 32.2222Z"
        fill={color}
      />
      <path
        d="M22.5381 31.4344C23.6255 30.7203 23.6115 28.7775 22.5067 27.095C21.4018 25.4126 19.6247 24.6275 18.5372 25.3416C17.4498 26.0557 17.4638 27.9985 18.5686 29.681C19.6735 31.3634 21.4506 32.1485 22.5381 31.4344Z"
        fill={color}
      />
      <path
        d="M43.3175 29.6976C44.4223 28.0151 44.4363 26.0723 43.3489 25.3582C42.2614 24.6442 40.4843 25.4292 39.3794 27.1117C38.2746 28.7941 38.2606 30.7369 39.348 31.451C40.4355 32.1651 42.2127 31.3801 43.3175 29.6976Z"
        fill={color}
      />
      <path
        d="M27.4769 26.0089C28.87 25.5309 29.418 23.4494 28.7009 21.3597C27.9838 19.2701 26.2732 17.9637 24.8801 18.4417C23.487 18.9198 22.939 21.0013 23.6561 23.0909C24.3732 25.1806 26.0838 26.487 27.4769 26.0089Z"
        fill={color}
      />
      <path
        d="M37.6748 23.0856C38.3919 20.996 37.8439 18.9145 36.4508 18.4364C35.0578 17.9583 33.3471 19.2648 32.63 21.3544C31.9129 23.444 32.461 25.5255 33.854 26.0036C35.2471 26.4817 36.9577 25.1752 37.6748 23.0856Z"
        fill={color}
      />
      <path
        d="M30.9333 5.33332C44.8 5.33332 56.0889 16.5778 56.0889 30.4C56.0889 44.2222 44.8 55.4667 30.9333 55.4667C17.0667 55.4667 5.77778 44.1778 5.77778 30.4C5.77778 16.6222 17.0667 5.33332 30.9333 5.33332ZM30.9333 2.66666C15.5556 2.66666 3.11111 15.0667 3.11111 30.4C3.11111 45.7333 15.5556 58.1333 30.9333 58.1333C46.3111 58.1333 58.7556 45.7333 58.7556 30.4C58.7556 15.0667 46.3111 2.66666 30.9333 2.66666Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationLostThin;
