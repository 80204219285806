/**
 * Heading Font Classes Used to set particular classes for particular fonts
 *
 * @constant
 */
export const headingFontClasses = {
  petco: 'font-bold',
  amasis: '',
};

/**
 * Body Size Classes Used to the set the classes for the body size
 *
 * @constant
 */
export const bodySizeClasses = {
  body1: 'text-[1.375rem] leading-8',
  body2: 'text-xl',
  body3: 'text-lg leading-[1.625rem]',
  body4: 'text-base',
  body5: 'text-sm leading-[1.375rem]',
  overline: 'text-xs leading-5',
};

/**
 * Body Styling Classes Used to the set the classes for the body styling
 *
 * @constant
 */
export const bodyStylingClasses = {
  regular: '',
  bold: 'font-bold',
  italic: 'italic',
  boldItalic: 'font-bold italic',
  link: 'text-base-400 underline',
};
