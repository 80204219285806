import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineClose: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      data-testid="icon-outline-close"
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7656 15.7072C17.0469 16.0187 17.0469 16.486 16.7656 16.7664C16.4531 17.0779 15.9844 17.0779 15.7031 16.7664L12.0156 13.0592L8.29688 16.7664C7.98438 17.0779 7.51562 17.0779 7.23438 16.7664C6.92188 16.486 6.92188 16.0187 7.23438 15.7072L10.9531 12L7.23438 8.29283C6.92188 7.98131 6.92188 7.51402 7.23438 7.23364C7.51562 6.92212 7.98438 6.92212 8.26562 7.23364L12.0156 10.972L15.7344 7.2648C16.0156 6.95327 16.4844 6.95327 16.7656 7.2648C17.0781 7.54517 17.0781 8.01246 16.7656 8.32399L13.0469 12L16.7656 15.7072Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineClose;
