import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationMap: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-map',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-map"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.1778 71.6H56.1333C55.3778 71.5555 54.8444 70.9333 54.8444 70.2222L55.7333 50.7555C55.7778 49.7333 56.1333 48.8 56.7556 48.0444C60.4 43.5555 70.2667 43.0222 70.7111 42.9777C71.4222 42.9333 72.0889 43.5111 72.0889 44.2222C72.1333 44.9777 71.5556 45.6 70.8444 45.6C68.3111 45.7333 61.2444 46.7111 58.8444 49.6444C58.5778 49.9555 58.4444 50.3555 58.4 50.8L57.5111 70.2666C57.4667 71.0666 56.8889 71.6 56.1778 71.6Z"
        fill={color}
      />
      <path
        d="M74.7111 72.8888H5.28889C4 72.8888 2.8 72.3111 2 71.2888C1.2 70.2666 0.933334 68.9777 1.28889 67.6889L11.0222 31.0222C11.5111 29.2 13.1556 27.9555 15.0222 27.9555H22.1778C22.9333 27.9555 23.5111 28.5333 23.5111 29.2889C23.5111 30.0444 22.9333 30.6222 22.1778 30.6222H15.0222C14.3556 30.6222 13.7778 31.0666 13.6 31.7333L3.86667 68.3555C3.73333 68.8 3.82222 69.2444 4.13333 69.6444C4.4 70 4.84444 70.2222 5.28889 70.2222H74.7111C75.1556 70.2222 75.6 70 75.8667 69.6444C76.1333 69.2888 76.2222 68.8 76.1333 68.3555L66.4 31.6889C66.2222 31.0222 65.6444 30.5777 64.9778 30.5777H31.6444C30.8889 30.5777 30.3111 30 30.3111 29.2444C30.3111 28.4889 30.8889 27.9111 31.6444 27.9111H64.9778C66.8444 27.9111 68.4889 29.2 68.9778 30.9777L78.7111 67.6444C79.0222 68.8888 78.8 70.2222 78 71.2444C77.2 72.3111 76 72.8888 74.7111 72.8888Z"
        fill={color}
      />
      <path
        d="M55.6889 44.3555C54.4 44.3555 53.0667 43.7777 52.2222 42.6666L43.1111 30.8888C42.6667 30.3111 42.7556 29.4666 43.3333 29.0222C43.9111 28.5777 44.7556 28.6666 45.2 29.2444L54.3111 41.0222C54.8444 41.6888 55.7778 41.8666 56.4889 41.4222C61.2 38.3999 69.5111 38.2666 69.8667 38.2222C70.6222 38.1777 71.2 38.7999 71.2 39.5555C71.2 40.3111 70.6222 40.8888 69.8667 40.8888C69.7778 40.8888 62 41.0222 57.9111 43.6444C57.2444 44.1777 56.4444 44.3555 55.6889 44.3555Z"
        fill={color}
      />
      <path
        d="M44.9333 45.5555C44.8889 45.5555 44.8 45.5555 44.7556 45.5555C28.5778 44.7555 10.4889 38.4888 10.3111 38.4444C9.6 38.2222 9.24444 37.4222 9.51111 36.7555C9.73333 36.0444 10.5333 35.6888 11.2 35.9555C11.3778 35.9999 29.1111 42.1777 44.9333 42.9333C45.3333 42.9333 45.5111 42.6666 45.5556 42.5777C45.6 42.4444 45.7333 42.1777 45.4667 41.8666L36.4889 30.9333C36 30.3555 36.0889 29.5111 36.6667 29.0666C37.2444 28.5777 38.0889 28.6666 38.5333 29.2444L47.4667 40.1333C48.3111 41.1555 48.4889 42.5333 47.8667 43.7333C47.3333 44.8888 46.2222 45.5555 44.9333 45.5555Z"
        fill={color}
      />
      <path
        d="M3.91111 64.3555C3.33334 64.3555 2.8 63.9999 2.62222 63.4222C2.4 62.711 2.8 61.9555 3.51111 61.7333C6.26667 60.8444 12.1333 55.4222 19.2 47.1555C19.2 47.1555 19.2444 47.111 19.2 47.111C19.2 47.0666 19.1556 47.0666 19.1556 47.0666C12.4 45.6888 10.6222 44.711 9.91111 44.1333C9.33334 43.6888 9.24445 42.8444 9.73334 42.2666C10.2222 41.6888 11.0222 41.5999 11.6 42.0888C11.9556 42.3999 13.4667 43.1999 19.7333 44.4888C20.7111 44.6666 21.4667 45.3777 21.7778 46.2666C22.0889 47.1999 21.9111 48.1777 21.2889 48.9333C16.8889 54.0888 8.84445 62.9333 4.35556 64.3555C4.17778 64.311 4.04445 64.3555 3.91111 64.3555Z"
        fill={color}
      />
      <path
        d="M49.3778 72.8889C49.3333 72.8889 49.2889 72.8889 49.2889 72.8889C48.5333 72.8444 48 72.1778 48.0444 71.4667L49.2444 55.9111C49.4222 53.5111 47.6889 51.3778 45.2889 51.0666C41.1556 50.5333 35.0222 49.7333 29.0222 48.8C28.5778 48.7111 28.1333 48.8889 27.8222 49.2C23.5556 53.6889 9.24444 68.6666 3.55555 71.5111C2.88889 71.8222 2.08889 71.5555 1.77778 70.9333C1.46667 70.2667 1.73333 69.4667 2.35555 69.1555C6.53333 67.0667 17.2444 56.5333 25.8667 47.4222C26.8 46.4444 28.0889 46 29.4222 46.2222C35.3778 47.1555 41.5111 48 45.6 48.4889C49.4222 48.9778 52.1333 52.3555 51.8667 56.1778L50.6667 71.7333C50.6222 72.3555 50.0444 72.8889 49.3778 72.8889Z"
        fill={color}
      />
      <path
        d="M27.2444 36.7111C26.8 36.7111 26.4 36.4889 26.1333 36.1333L25.9556 35.8666C23.6444 32.4444 21.2889 28.9333 18.8444 25.5111C18.2222 24.6666 17.6 23.7778 17.0667 22.7555C15.4222 19.4666 15.6444 15.6 17.6889 12.3555C19.7778 9.06664 23.2444 7.11108 26.9778 7.11108C27.7333 7.11108 28.3111 7.68886 28.3111 8.44442C28.3111 9.19997 27.7333 9.77775 26.9778 9.77775C24.1778 9.77775 21.5556 11.2889 20 13.7778C18.4889 16.2222 18.3111 19.1111 19.5111 21.6C19.9111 22.4 20.4444 23.2 21.0667 24C23.5111 27.4222 25.9111 30.9778 28.2222 34.4L28.4 34.6666C28.8 35.2889 28.6667 36.0889 28.0444 36.5333C27.7778 36.6666 27.5111 36.7111 27.2444 36.7111Z"
        fill={color}
      />
      <path
        d="M27.5556 36.7111C27.2889 36.7111 27.0222 36.6222 26.8 36.4889C26.1778 36.0889 26.0444 35.2444 26.4444 34.6222L26.6222 34.4C28.8889 30.9778 31.2889 27.4222 33.7333 24C34.3111 23.2 34.8444 22.4444 35.2889 21.6C36.4889 19.1555 36.3111 16.2222 34.8 13.7778C33.2444 11.2889 30.6222 9.77775 27.8222 9.77775C27.0667 9.77775 26.4889 9.19997 26.4889 8.44442C26.4889 7.68886 27.0667 7.11108 27.8222 7.11108C31.5556 7.11108 35.0222 9.06664 37.0667 12.3555C39.0667 15.6 39.2889 19.4666 37.6889 22.7555C37.2 23.7778 36.5333 24.6666 35.9111 25.5111C33.4667 28.9333 31.1111 32.4444 28.8 35.8666L28.6222 36.0889C28.4 36.5333 27.9556 36.7111 27.5556 36.7111Z"
        fill={color}
      />
      <path
        d="M27.3778 24.3555C23.9111 24.3555 21.1111 21.5555 21.1111 18.0889C21.1111 14.6222 23.9111 11.8222 27.3778 11.8222C30.8444 11.8222 33.6444 14.6222 33.6444 18.0889C33.6444 21.5555 30.8444 24.3555 27.3778 24.3555ZM27.3778 14.4889C25.3778 14.4889 23.7778 16.0889 23.7778 18.0889C23.7778 20.0889 25.3778 21.6889 27.3778 21.6889C29.3778 21.6889 30.9778 20.0889 30.9778 18.0889C30.9778 16.0889 29.3778 14.4889 27.3778 14.4889Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationMap;
