import IIconType from '../IIconType';

/**
 * @param {IIconType} props - The props
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineNeighbors: React.FC<IIconType> = ({ size }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-neighbors"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.64,8.84c-1.98-0.82-4.1-1.22-6.24-1.19C17,7.74,11.28,11.63,8.85,17.54s-1.1,12.68,3.37,17.24 c0.07,0.07,0.18,0.09,0.26,0.03c0.09-0.05,0.12-0.16,0.09-0.25c-0.27-0.69-0.49-1.41-0.65-2.13c-0.41-1.97-0.21-2.24-0.17-2.58 c0.09-0.74,0.61-1.35,1.6-1.86c0.89-0.46,2.04-0.77,2.98-0.99c-0.14,1.32-0.13,2.66,0.05,3.97c0.33,2.15,1.02,4.15,1.54,5.12 c0.04,0.07,0.11,0.11,0.19,0.11c0.02,0,0.05,0,0.07-0.01c0.1-0.03,0.16-0.13,0.14-0.23c-0.2-1.27-0.84-7.41-0.14-9.4 c0.67-1.91,3.05-2.17,5.48-2.17c2.43,0,4.85,0.24,5.48,2.17c0.57,1.74,0.11,7.78-0.13,9.32c-0.02,0.1,0.04,0.2,0.14,0.23 c0.1,0.03,0.21-0.01,0.25-0.1c0.76-1.59,1.27-3.29,1.5-5.04c0.15-1.32,0.16-2.65,0.05-3.97c2.18,0.52,4.39,1.28,4.58,2.85 c0.04,0.35,0.15,1.01-0.17,2.58c-0.16,0.73-0.38,1.44-0.65,2.13c-0.04,0.09,0,0.2,0.09,0.25c0.08,0.06,0.2,0.04,0.26-0.03 c2.96-3,4.61-7.05,4.57-11.26c-0.01-2.14-0.46-4.25-1.32-6.2C36.62,13.48,33.51,10.44,29.64,8.84z M15.58,24.81 c-1.12,0-2.13-0.67-2.56-1.71c-0.43-1.03-0.19-2.23,0.6-3.02s1.99-1.03,3.02-0.6c1.04,0.43,1.71,1.44,1.71,2.56 C18.35,23.57,17.11,24.81,15.58,24.81z M23.63,22.24c-1.85,0-3.35-1.5-3.35-3.34c0-1.85,1.5-3.34,3.35-3.34 c1.85,0,3.35,1.5,3.35,3.34C26.98,20.74,25.48,22.24,23.63,22.24L23.63,22.24z M31.68,24.81c-1.53,0-2.77-1.24-2.77-2.77 c0-1.53,1.24-2.77,2.77-2.77s2.77,1.24,2.77,2.77C34.46,23.57,33.22,24.81,31.68,24.81z M23.63,3.08 c-11.35,0-20.55,9.2-20.55,20.55s9.2,20.55,20.55,20.55c11.35,0,20.55-9.2,20.55-20.55C44.18,12.29,34.98,3.09,23.63,3.08z M42.14,23.63c0,10.22-8.29,18.51-18.51,18.51c-10.22,0-18.51-8.29-18.51-18.51S13.41,5.13,23.63,5.13 C33.85,5.14,42.13,13.42,42.14,23.63z"
        fill="white"
      ></path>
    </svg>
  );
};

export default IconOutlineNeighbors;
