import { NextRequest } from 'next/server';

export const featureFlagOverrideKey = 'feature-flag-override';

/**
 * Look for a feature flag over ride in the cookies or environment variables
 *
 * @param {NextRequest} request - The incoming request
 * @returns {string | null} - The feature flag override if present, otherwise
 *   null
 */
export function getFeatureFlagOverride(request: NextRequest): string | null {
  // Look for the feature flag override in the cookies
  const overrideCookie = request.cookies.get(featureFlagOverrideKey);
  if (overrideCookie) {
    return overrideCookie.value;
  }
  // Look for the feature flag override in the environment variables
  const envOverrideFlag = process.env.FEATURE_FLAG_OVERRIDE;
  if (envOverrideFlag) {
    return envOverrideFlag;
  }

  // Return null if no feature flag override is found
  return null;
}
