import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IllustrationCamera: React.FC<IIconType> = ({
  size,
  color,
  id = 'illustration-camera',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 80 80"
      fill="none"
      id={id}
      data-testid="icon-illustration-camera"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.5778 68.9778H9.42224C5.68891 68.9778 2.66669 65.6444 2.66669 61.5555V28.8889C2.66669 24.8 5.68891 21.4667 9.42224 21.4667H22.2222L23.2 15.2C23.5556 12.7556 25.5556 11.0222 27.8667 11.0222H52.1778C54.4889 11.0222 56.4889 12.8 56.8445 15.2L57.8222 21.4667H70.5778C74.3111 21.4667 77.3334 24.8 77.3334 28.8889V61.5555C77.3334 65.6444 74.3111 68.9778 70.5778 68.9778ZM9.42224 24.1333C7.15558 24.1333 5.33335 26.2667 5.33335 28.8889V61.5555C5.33335 64.1778 7.15558 66.3111 9.42224 66.3111H70.5333C72.8 66.3111 74.6222 64.1778 74.6222 61.5555V28.8889C74.6222 26.2667 72.8 24.1333 70.5333 24.1333H55.4667L54.1334 15.6C53.9556 14.4889 53.1111 13.6444 52.0889 13.6444H27.8222C26.8 13.6444 25.9556 14.4444 25.7778 15.6L24.4445 24.1333H9.42224Z"
        fill={color}
      />
      <path
        d="M69.5111 34.3111H62.5778C61.1111 34.3111 59.9111 33.1111 59.9111 31.6444V29.2C59.9111 27.7333 61.1111 26.5333 62.5778 26.5333H69.5111C70.9778 26.5333 72.1778 27.7333 72.1778 29.2V31.6444C72.1778 33.1555 70.9778 34.3111 69.5111 34.3111ZM69.5111 29.2H62.5778V31.6444H69.5111V29.2Z"
        fill={color}
      />
      <path
        d="M40 58.5778C32.0445 58.5778 25.5556 52.0889 25.5556 44.1333C25.5556 36.1778 32.0445 29.6889 40 29.6889C47.9556 29.6889 54.4445 36.1778 54.4445 44.1333C54.4445 52.0889 47.9556 58.5778 40 58.5778ZM40 32.3556C33.5111 32.3556 28.2222 37.6445 28.2222 44.1333C28.2222 50.6222 33.5111 55.9111 40 55.9111C46.4889 55.9111 51.7778 50.6222 51.7778 44.1333C51.7778 37.6445 46.4889 32.3556 40 32.3556Z"
        fill={color}
      />
      <path
        d="M40 54.7556C39.2445 54.7556 38.6667 54.1778 38.6667 53.4222C38.6667 52.6667 39.2445 52.0889 40 52.0889C44.4 52.0889 48 48.4889 48 44.0889C48 43.3333 48.5778 42.7556 49.3334 42.7556C50.0889 42.7556 50.6667 43.3333 50.6667 44.0889C50.6667 50 45.8667 54.7556 40 54.7556Z"
        fill={color}
      />
      <path
        d="M12.2667 63.9555C11.2 63.9555 9.42223 62.4444 9.33335 62.4C8.62223 61.8222 7.20001 60.5778 7.20001 58.4444C7.20001 57.4222 7.55557 56.5333 8.17779 55.8666C8.80001 55.2444 9.64446 54.8444 10.4445 54.8444C11.2 54.8444 11.7778 55.1111 12.2222 55.4222C12.6667 55.1111 13.2889 54.8444 14 54.8444C15.6 54.8444 17.2889 56.1778 17.2889 58.4444C17.2889 60.5778 15.8667 61.8222 15.1556 62.4444C15.0222 62.5333 13.3333 63.9555 12.2667 63.9555ZM10.4889 57.4666C10.4 57.4666 10.2667 57.5111 10.1333 57.6444C10 57.7778 9.86668 58 9.86668 58.4C9.86668 59.1555 10.3111 59.6889 11.0667 60.3555C11.4667 60.7111 11.9556 61.0222 12.2667 61.2C12.5778 61.0222 13.0222 60.7111 13.4667 60.3555C14.2667 59.6889 14.6667 59.1555 14.6667 58.4C14.6667 57.6889 14.2222 57.4666 14.0445 57.4666C13.8222 57.4666 13.5111 57.8222 13.3778 58C13.1111 58.4 12.6667 58.6222 12.2222 58.6222C11.7333 58.6222 11.3333 58.3555 11.1111 57.9555C11.0222 57.8222 10.7111 57.4666 10.4889 57.4666Z"
        fill={color}
      />
      <path
        d="M27.5556 40.5333H5.33333C4.57778 40.5333 4 39.9555 4 39.2C4 38.4444 4.57778 37.8666 5.33333 37.8666H27.5556C28.3111 37.8666 28.8889 38.4444 28.8889 39.2C28.8889 39.9555 28.3111 40.5333 27.5556 40.5333Z"
        fill={color}
      />
      <path
        d="M76 40.5333H52.4445C51.6889 40.5333 51.1111 39.9555 51.1111 39.2C51.1111 38.4444 51.6889 37.8666 52.4445 37.8666H76C76.7556 37.8666 77.3334 38.4444 77.3334 39.2C77.3334 39.9555 76.7556 40.5333 76 40.5333Z"
        fill={color}
      />
    </svg>
  );
};

export default IllustrationCamera;
