import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineCrosshairsLight: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-outline-crosshairs-light"
    >
      <path
        d="M20.7266 11.375H17C16.6562 11.375 16.375 11.6562 16.375 12C16.375 12.3438 16.6562 12.625 17 12.625H20.7266C20.4219 16.9609 16.9609 20.4219 12.625 20.7266V17C12.625 16.6562 12.3438 16.375 12 16.375C11.6562 16.375 11.375 16.6562 11.375 17V20.7266C7.03906 20.4219 3.57812 16.9609 3.27344 12.625H7C7.34375 12.625 7.625 12.3438 7.625 12C7.625 11.6562 7.34375 11.375 7 11.375H3.27344C3.57812 7.03906 7.03906 3.57812 11.375 3.27344V7C11.375 7.34375 11.6562 7.625 12 7.625C12.3438 7.625 12.625 7.34375 12.625 7V3.27344C16.9609 3.57812 20.4219 7.03906 20.7266 11.375ZM2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineCrosshairsLight;
