/**
 * Function to handle the case where the URL starts with "//" or is missing the
 * https from the string and adds "https:" to the beginning.
 *
 * @param {string} url The url to check for missing https
 * @returns {string} The url with https
 */
export function addHttps(url?: string): string {
  if (!url) return '';

  if (url.startsWith('//')) {
    url = 'https:' + url;
  } else if (!url.startsWith('https://') && !url.startsWith('http://')) {
    url = 'https://' + url;
  }
  return url;
}
