import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineMoreVertical: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8C14 8.84375 13.3203 9.5 12.5 9.5C11.6562 9.5 11 8.84375 11 8C11 7.17969 11.6562 6.5 12.5 6.5C13.3203 6.5 14 7.17969 14 8ZM14 17C14 17.8438 13.3203 18.5 12.5 18.5C11.6562 18.5 11 17.8438 11 17C11 16.1797 11.6562 15.5 12.5 15.5C13.3203 15.5 14 16.1797 14 17ZM14 12.5C14 13.3438 13.3203 14 12.5 14C11.6562 14 11 13.3438 11 12.5C11 11.6797 11.6562 11 12.5 11C13.3203 11 14 11.6797 14 12.5Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineMoreVertical;
