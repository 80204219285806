import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineChevronLeftCircled: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-outline-chevron-left-circled',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-chevron-left-circled"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke={color} />
        <path
          d="M14.3183 18.5L8.59955 12.5313C8.4433 12.3438 8.3808 12.1563 8.3808 11.9688C8.3808 11.8125 8.4433 11.625 8.5683 11.4688L14.287 5.5C14.5683 5.1875 15.0683 5.1875 15.3495 5.46875C15.662 5.75 15.662 6.21875 15.3808 6.53125L10.162 11.9688L15.412 17.4688C15.6933 17.75 15.6933 18.25 15.3808 18.5313C15.0995 18.8125 14.5995 18.8125 14.3183 18.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconOutlineChevronLeftCircled;
