import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineChevronUp: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-outline-chevron-up',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-chevron-up"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M15.9999 14.5C15.8683 14.5007 15.7379 14.4755 15.616 14.4258C15.4942 14.376 15.3834 14.3027 15.2899 14.21L11.9999 10.9L8.69994 14.08C8.51258 14.2662 8.25912 14.3708 7.99494 14.3708C7.73075 14.3708 7.4773 14.2662 7.28994 14.08C7.19621 13.987 7.12182 13.8764 7.07105 13.7546C7.02028 13.6327 6.99414 13.502 6.99414 13.37C6.99414 13.238 7.02028 13.1073 7.07105 12.9854C7.12182 12.8636 7.19621 12.7529 7.28994 12.66L11.2899 8.79999C11.4769 8.61676 11.7282 8.51413 11.9899 8.51413C12.2517 8.51413 12.503 8.61676 12.6899 8.79999L16.6899 12.8C16.7837 12.893 16.8581 13.0036 16.9088 13.1254C16.9596 13.2473 16.9857 13.378 16.9857 13.51C16.9857 13.642 16.9596 13.7727 16.9088 13.8946C16.8581 14.0164 16.7837 14.127 16.6899 14.22C16.5046 14.3987 16.2574 14.499 15.9999 14.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconOutlineChevronUp;
