/**
 * Return the base path for the application. Will look for the
 * NEXT_PUBLIC_BASE_PATH environment variable. If none exist, will fallback to
 * an empty string
 *
 * @param {string} fallback - Fallback path if no base path is found. Defaults
 *   to ''
 * @returns {string} The base path.
 */
export default function getBasePath(fallback = ''): string {
  return process.env.NEXT_PUBLIC_BASE_PATH || fallback;
}
