import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineFlag: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-flag"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5625 4.19157C18.9375 3.81592 18.3438 4.09766 18.25 4.16027C16.9688 4.75504 15.875 5.03677 14.9062 5.03677C14 5.03677 13.2812 4.78634 12.5312 4.53591C11.7188 4.25418 10.875 4.00375 9.78125 4.00375C8.96875 4.00375 7.78125 4.06636 5 5.19329V4.50461C5 4.22287 4.75 4.00375 4.5 4.00375C4.21875 4.00375 4 4.22287 4 4.50461V19.4991C4 19.7809 4.21875 20 4.5 20C4.75 20 5 19.7809 5 19.4991V16.2435C5.4375 16.0244 7.40625 15.0227 9.625 15.0227C10.7812 15.0227 11.7188 15.2418 12.75 15.5236C13.6875 15.7427 14.6875 15.9931 15.8438 15.9931C16.9375 15.9931 18.0938 15.774 19.3125 15.2731C19.6875 15.1479 19.9375 14.7723 19.9375 14.3653L20 5.00547C20 4.69243 19.8438 4.34809 19.5625 4.19157ZM18.9688 14.334C17.8438 14.8036 16.8125 14.9914 15.8438 14.9914C14.8125 14.9914 13.9375 14.7723 13 14.5531C11.9688 14.2714 10.9062 14.021 9.625 14.021C8.1875 14.021 6.71875 14.3653 5.0625 15.0853L5 15.1479V6.28893L5.875 5.94458C7.40625 5.31851 8.6875 5.00547 9.78125 5.00547C10.7188 5.00547 11.4688 5.2246 12.2188 5.47503C13 5.72546 13.8438 6.00719 14.9062 6.00719C17.0625 6.00719 19 5.00547 19 5.00547L18.9688 14.334Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineFlag;
