import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineUserCircle: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-user-circle"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.45312 2 2 6.49219 2 12C2 17.5469 6.45312 22 12 22C17.5078 22 22 17.5469 22 12C22 6.49219 17.5078 2 12 2ZM12 20.75C10.125 20.75 8.40625 20.2031 7 19.2266C7.15625 17.2734 8.75781 15.75 10.75 15.75H13.25C15.2031 15.75 16.8047 17.2734 16.9609 19.2266C15.5547 20.2031 13.8359 20.75 12 20.75ZM18.0547 18.2891C17.5078 16.1406 15.5547 14.5 13.25 14.5H10.75C8.40625 14.5 6.45312 16.1406 5.90625 18.2891C4.26562 16.6875 3.25 14.4609 3.25 12C3.25 7.19531 7.15625 3.25 12 3.25C16.8047 3.25 20.75 7.19531 20.75 12C20.75 14.4609 19.6953 16.6875 18.0547 18.2891ZM12 7C10.2422 7 8.875 8.40625 8.875 10.125C8.875 11.8828 10.2422 13.25 12 13.25C13.7188 13.25 15.125 11.8828 15.125 10.125C15.125 8.40625 13.7188 7 12 7ZM12 12C10.9453 12 10.125 11.1797 10.125 10.125C10.125 9.10938 10.9453 8.25 12 8.25C13.0156 8.25 13.875 9.10938 13.875 10.125C13.875 11.1797 13.0156 12 12 12Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineUserCircle;
