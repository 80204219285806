/**
 * Describe the params to get the image path function
 *
 * @interface IGetImagePath
 */
interface IGetImagePath {
  /** The image src */
  src: string;
  /** The CDN base URL */
  cdnBaseUrl: string;
}

/**
 * Get the image path from an image src and the CDN base URL by removing the CDN
 * base URL from the image src. If the image src is a full path, return it as
 * is.
 *
 * @param {IGetImagePath} params - Get image path params
 * @returns {string} - Image path
 */
export function getImagePath({ src, cdnBaseUrl }: IGetImagePath): string {
  /**
   * Image path without cdn base path
   *
   * @constant {string} imageSrcPath - The image src path
   */
  const imagePath = src.replace(cdnBaseUrl, '');

  /** If we get a cdn resource full path, remove the query params and return it */
  if (imagePath.startsWith('/')) {
    return imagePath.split('?')[0];
  }

  /**
   * If the provided image path is not a full path or an external URL that we
   * can load through the CDN (Example: images from legacy S3 bucket) we assume
   * it's a relative path
   */
  return `/${imagePath}`;
}
