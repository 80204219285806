import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlineYoutube: React.FC<IIconType> = ({
  size,
  color,
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-social-media-youtube"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5612 7.23035C21.3419 6.35283 20.6472 5.65814 19.8062 5.43876C18.234 5 12.0183 5 12.0183 5C12.0183 5 5.766 5 4.19378 5.43876C3.35283 5.65814 2.65814 6.35283 2.43876 7.23035C2 8.766 2 12.0567 2 12.0567C2 12.0567 2 15.3108 2.43876 16.883C2.65814 17.7605 3.35283 18.4186 4.19378 18.638C5.766 19.0402 12.0183 19.0402 12.0183 19.0402C12.0183 19.0402 18.234 19.0402 19.8062 18.638C20.6472 18.4186 21.3419 17.7605 21.5612 16.883C22 15.3108 22 12.0567 22 12.0567C22 12.0567 22 8.766 21.5612 7.23035ZM9.97075 15.0183V9.09506L15.1627 12.0567L9.97075 15.0183Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlineYoutube;
