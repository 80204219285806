import React from 'react';
import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color, id }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconFillSearch: React.FC<IIconType> = ({
  size,
  color,
  id = 'icon-fill-search',
}: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 32"
      fill="none"
      data-testid="icon-fill-search"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" width="32" height="32" rx="16" fill={color} />

      <g clipPath={`url(#${id})`}>
        <path
          d="M25.21 23.29L21.81 19.9C22.907 18.5025 23.5022 16.7767 23.5 15C23.5 13.4178 23.0308 11.871 22.1518 10.5554C21.2727 9.23985 20.0233 8.21447 18.5615 7.60897C17.0997 7.00347 15.4911 6.84504 13.9393 7.15372C12.3874 7.4624 10.962 8.22433 9.84315 9.34315C8.72433 10.462 7.9624 11.8874 7.65372 13.4393C7.34504 14.9911 7.50347 16.5997 8.10897 18.0615C8.71447 19.5233 9.73985 20.7727 11.0554 21.6518C12.371 22.5308 13.9178 23 15.5 23C17.2767 23.0022 19.0025 22.407 20.4 21.31L23.79 24.71C23.883 24.8037 23.9936 24.8781 24.1154 24.9289C24.2373 24.9797 24.368 25.0058 24.5 25.0058C24.632 25.0058 24.7627 24.9797 24.8846 24.9289C25.0064 24.8781 25.117 24.8037 25.21 24.71C25.3037 24.617 25.3781 24.5064 25.4289 24.3846C25.4797 24.2627 25.5058 24.132 25.5058 24C25.5058 23.868 25.4797 23.7373 25.4289 23.6154C25.3781 23.4936 25.3037 23.383 25.21 23.29ZM9.5 15C9.5 13.8133 9.8519 12.6533 10.5112 11.6666C11.1705 10.6799 12.1075 9.91085 13.2039 9.45673C14.3003 9.0026 15.5067 8.88378 16.6705 9.11529C17.8344 9.3468 18.9035 9.91825 19.7426 10.7574C20.5818 11.5965 21.1532 12.6656 21.3847 13.8295C21.6162 14.9933 21.4974 16.1997 21.0433 17.2961C20.5892 18.3925 19.8201 19.3295 18.8334 19.9888C17.8467 20.6481 16.6867 21 15.5 21C13.9087 21 12.3826 20.3679 11.2574 19.2426C10.1321 18.1174 9.5 16.5913 9.5 15Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(4.5 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconFillSearch;
