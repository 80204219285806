import CdnImage from '@/components/atoms/CdnImage/CdnImage';
import CalloutWithButtons, {
  ICalloutWithButtons,
} from '@/components/molecules/CalloutWithButtons/CalloutWithButtons';
import useWindowSize, { breakpoint } from '@/lib/hooks/windowSize/WindowSize';
import { cva } from 'cva';
import { useEffect, useState } from 'react';

/** AllowedErrorCodes - Describes the allowed error codes */
export type AllowedErrorCodes = 403 | 404 | 500;

/**
 * IImageDimensions
 *
 * @interface
 */
interface IImageDimensions {
  /** The width of the image */
  width: number;
  /** The height of the image */
  height: number;
}

/**
 * IErrorTemplate
 *
 * @interface
 */
export interface IErrorTemplate {
  /** Text title of the Callout With Buttons component */
  title: string;
  /** Text description of the Callout With Buttons component */
  description: string;
  /** The error coed */
  errorCode: AllowedErrorCodes;
}

/**
 * ImageContainerClasses Classes for the image container using CVA
 *
 * This is used to determine the classes for the image container based
 *
 * @constant
 */
const imageContainerClasses = cva('absolute', {
  variants: {
    error: {
      403: 'top-[466px] sm:top-[527px] md:top-[601px] ',
      404: 'top-[466px] sm:top-[527px] md:top-[601px] ',
      500: 'top-[457px] sm:top-[520px] md:top-[595px]',
    },
  },
});

/**
 * ErrorTemplate - The template for the 404 page
 *
 * @param {IErrorTemplate} props - The props for the ErrorTemplate component
 * @returns {React.FC<IErrorTemplate>} Component
 */
const ErrorTemplate: React.FC<IErrorTemplate> = ({
  title,
  description,
  errorCode,
}) => {
  const { breakpoint } = useWindowSize();
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
  });

  /** Update the size of the avatar according to the window size */
  useEffect(() => {
    /**
     * Map the breakpoint size to the 404 image size
     *
     * @constant {object} error404breakpointToImageSizeMap
     */
    const error404breakpointToImageSizeMap: {
      [key in breakpoint]: IImageDimensions;
    } = {
      xs: { width: 217, height: 184 },
      sm: { width: 265, height: 224 },
      md: { width: 265, height: 224 },
    };

    /**
     * Map the breakpoint size to the 500 image size
     *
     * @constant {object} error404breakpointToImageSizeMap
     */
    const error500breakpointToImageSizeMap: {
      [key in breakpoint]: IImageDimensions;
    } = {
      xs: { width: 227, height: 176 },
      sm: { width: 271, height: 210 },
      md: { width: 271, height: 210 },
    };

    if (breakpoint) {
      errorCode === 404 || errorCode === 403
        ? setImageSize(error404breakpointToImageSizeMap[breakpoint])
        : setImageSize(error500breakpointToImageSizeMap[breakpoint]);
    }
  }, [breakpoint, errorCode]);

  /**
   * The props for the Callout With Buttons component
   *
   * @constant {ICalloutWithButtons} ICalloutWithButtons
   */
  const calloutWithButtonsProps: ICalloutWithButtons = {
    title: (
      <span className="font-petco italic text-[5rem] sm:text-[5rem] md:text-[5.625rem] text-base-400 whitespace-nowrap">
        {title}
      </span>
    ),

    description: description,
    buttons: [
      {
        children: 'Go to Homepage',
        type: 'button',
        size: 'md',
        variant: 'light',
        color: 'primary',
        href: '/',
      },
      {
        children: 'Go to Help Center',
        type: 'button',
        size: 'md',
        variant: 'light',
        color: 'secondary',
        href: 'https://support.lost.petcolove.org/hc/en-us',
      },
    ],
  };

  /**
   * The image to display for a 404 error
   *
   * @constant {React.ReactNode} error404Image Error
   */
  const error404Image: React.ReactNode = (
    <div
      data-testid="404-image"
      className={imageContainerClasses({ error: errorCode })}
    >
      <CdnImage
        src={process.env.NEXT_PUBLIC_CDN_BASE_URL + '/assets/lost/404-dog.png'}
        alt="404"
        width={imageSize.width}
        height={imageSize.height}
      />
    </div>
  );

  /**
   * The image to display for a 500 error
   *
   * @constant {React.ReactNode} error500Image Error
   */
  const error500Image: React.ReactNode = (
    <div
      data-testid="500-image"
      className={imageContainerClasses({ error: errorCode })}
    >
      <CdnImage
        src={process.env.NEXT_PUBLIC_CDN_BASE_URL + '/assets/lost/500-dog.png'}
        alt="500"
        width={imageSize.width}
        height={imageSize.height}
      />
    </div>
  );

  return (
    <div data-testid="404-template">
      <div className="max-w-[100vw] -mb-5">
        <div className="bg-base-100 flex flex-col items-center w-full h-[572px] sm:h-[673px] md:h-[700px]">
          <span className="absolute flex items-center font-medium font-petco leading-[13rem] sm:leading-[22.375rem] md:leading-[25.5rem] text-[10.3125rem] sm:text-[21.875rem] md:text-[25rem] text-center text-neutral-100 z-0 top-[60px] sm:top-[166px] md:top-[234px]">
            {errorCode}
          </span>
          <CalloutWithButtons
            {...calloutWithButtonsProps}
            classes="absolute z-[1] mt-[70px] sm:mt-[185px] md:mt-[234px]"
          />
          {errorCode === 404 || errorCode === 403
            ? error404Image
            : error500Image}
        </div>
      </div>
    </div>
  );
};

export default ErrorTemplate;
