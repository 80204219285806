import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlinePlus: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-plus"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 19.9375C33 20.5 32.5 20.9375 32 20.9375H21V31.9375C21 32.5 20.5 33 20 33C19.4375 33 19 32.5 19 31.9375V20.9375H8C7.4375 20.9375 7 20.5 7 20C7 19.4375 7.4375 18.9375 8 18.9375H19V7.9375C19 7.4375 19.4375 7 20 7C20.5 7 21 7.4375 21 7.9375V18.9375H32C32.5 18.9375 33 19.4375 33 19.9375Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlinePlus;
