import IIconType from '../IIconType';

/**
 * @param {IIconType} props { Size, color }
 * @returns {React.FC<IIconType>} - The Icon Component
 */
const IconOutlinePin: React.FC<IIconType> = ({ size, color }: IIconType) => {
  return (
    <svg
      width={size}
      height={size}
      data-testid="icon-outline-pin"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.5C7.54167 2.5 4 5.85938 4 10C4 13.0469 5.08333 13.9062 11.1667 22.1094C11.375 22.3828 11.6667 22.5 12 22.5C12.2917 22.5 12.5833 22.3828 12.7917 22.1094C18.875 13.8672 20 13.0469 20 10C20 5.85938 16.4167 2.5 12 2.5ZM12 21.0156C6.125 13.125 5.33333 12.5 5.33333 10C5.33333 6.5625 8.29167 3.75 12 3.75C15.6667 3.75 18.6667 6.5625 18.6667 10C18.6667 12.5 17.8333 13.0859 12 21.0156Z"
        fill={color}
      />
    </svg>
  );
};

export default IconOutlinePin;
